import anime from "animejs/lib/anime.es.js";
import AOS from "aos";
// import LocomotiveScroll from "locomotive-scroll";
import { config } from "../config";
import { defaults } from "./defaults";
import lottie from "lottie-web";
// import gsap from "gsap";
// import TweenLite from "gsap/all";
// import "gsap/CSSPlugin";
// var LottieIsLoad = false;

var LOAD_STATUS = false;
var LOAD_TIMER;
var LOAD_PROGRESS = 0;
var MAX_PAGE_PROGRESS = config.getRandomInt(90, 98);

var animate = {
	selector: {
		page: "",
		morph: ".anime",
		up: ".js-item-up",
		parent: ".js-item-parent",
		links: ".js-hover-links a, .js-hover-link",
		text: ".js-text-wrap",
		textParent: ".js-text-parent",
		logo: ".js-logo-center",
		preloader: ".preloader",
	},

	lottie:(el) => {
		let elements = $(el);

		if(!elements.length)
			return false;

		// $(window).width() <= 580

		let lottie_anim = (loader, file) => {
			// animate.lottieList[id] = lottie.loadAnimation({
			let item = lottie.loadAnimation({
				wrapper: loader,
				// renderer: 'svg',
				renderer: 'canvas',
				// animType: 'svg',

				autoplay: true,
				loop: true,
				path: file
			});

			return item;
		}

		if(elements.length > 0){

			elements.each((i, el) => {
				let file  = $(el).data('file');
				let item = lottie_anim(el, file)

				if($(el).attr('data-speed')){
					item.setSpeed($(el).data('speed'))
				}
			})

		}
	},

	// importLottie: () => {
	// 	let $items = ".js-lottie";

	// 	if (!$items.length || LottieIsLoad) return false;

	// 	console.log("importLottie");

	// 	let $first = $("body section:first-child");

	// 	var svgLottie = {
	// 		selector: ".js-lottie",

	// 		settings: {
	// 			animType: "svg",
	// 			autoplay: false,
	// 			loop: false,
	// 		},

	// 		run: (el, file) => {
	// 			let clone = JSON.parse(JSON.stringify(svgLottie.settings));
	// 			var animItem;

	// 			clone.wrapper = el;
	// 			clone.path = file;

	// 			if ($(el).attr("data-autoplay")) {
	// 				clone.autoplay = true;
	// 				clone.loop = true;
	// 			}

	// 			if ($(window).width() <= 580) {
	// 				if ($(el).attr("data-mobile")) {
	// 					let file = $(el).data("mobile");

	// 					// svgLottie.load(file);
	// 					$(el).addClass("css-mobile-placeholder").load(file);
	// 				}
	// 			} else {
	// 				animItem = lottie.loadAnimation(clone);
	// 			}

	// 			if (!$(el).attr("data-autoplay") && $(window).width() > 580) {
	// 				$(el)
	// 					.parent()
	// 					.hover(
	// 						() => {
	// 							animItem.setSpeed(1);
	// 							animItem.setDirection(1);
	// 							animItem.play();
	// 						},
	// 						() => {
	// 							if (
	// 								animItem.currentFrame <
	// 								animItem.totalFrames - 1
	// 							) {
	// 								animItem.setDirection(-1);
	// 								animItem.setSpeed(2);
	// 								animItem.play();
	// 							} else {
	// 								animItem.stop();
	// 							}
	// 						}
	// 					);
	// 			}
	// 		},

	// 		init: () => {
	// 			if (!$(svgLottie.selector).length) return false;

	// 			$(svgLottie.selector).each((i, el) => {
	// 				let file = $(el).data("file");
	// 				svgLottie.run(el, file);
	// 			});
	// 		},
	// 	};

	// 	$(window).on("scroll", () => {
	// 		if (LottieIsLoad) return false;
	// 		let ww = $(window).scrollTop();

	// 		console.log("scroll Lottie");

	// 		if (ww > $first.offset().top + $first.outerHeight() / 2) {
	// 			LottieIsLoad = true;

	// 			if ($(window).width() > 580) {
	// 				$.getScript(
	// 					"/lottie.min.js",
	// 					(data, textStatus, jqxhr) => {
	// 						svgLottie.init();
	// 					}
	// 				);
	// 			} else {
	// 				svgLottie.init();
	// 			}
	// 		}
	// 	});
	// },


	center: {
		get: (el, hide = false) => {
			if (!$(el).length) return false;

			let $parent = $(el).parent();
			let width = $(window).width() / 2;
			let $wrap = $(".header__wrap");
			let wrapOffset = $wrap.offset().top;

			if (hide) {
				$(el).css({
					width: `${width}px`,
					visibility: "hidden",
				});
			}

			let offset = $(el).offset();
			// let pp = $(el).position();

			let windowTop = $(window).height() / 2;
			let windowLeft = $(window).width() / 2;

			let position = {
				y:
					windowTop -
					offset.top -
					(hide ? $(el).height() / 2 : 0) -
					wrapOffset,
				x: windowLeft - offset.left - (hide ? $(el).width() / 2 : 0),
				width: width,
			};

			// console.log("offset is ", offset);

			return position;
		},
		set: (el) => {
			if (!$(el).length) return false;

			let pos = animate.center.get(el, true);

			$(el)
				.css({
					transform: `translateX(${pos.x}px) translateY(${pos.y}px)`,
					visibility: "visible",
				})
				.addClass("is-visible");
		},
		in: (el) => {
			if (!$(el).length) return false;

			let pos = animate.center.get(el);

			let width = pos.width;

			let height = width / 4.57;

			// console.log(
			// 	`translateY: ${pos.y - height / 2}, translateX: ${
			// 		pos.x - width / 2
			// 	}, width: ${width}, height: ${height}`,
			// 	pos
			// );

			anime({
				targets: el,
				duration: 1100,
				easing: "easeOutCubic",
				translateY: pos.y - height / 2,
				translateX: pos.x - width / 2,
				width: pos.width,
			});
		},
		out: (el) => {
			if (!$(el).length) return false;

			let $parent = $(el).parent();
			let width = $parent.width();
			anime({
				targets: el,
				duration: 1100,
				easing: "easeOutCubic",
				translateY: 0,
				translateX: 0,
				width: width,
				complete: function (anim) {
					$(el).removeClass("is-visible");
				},
			});
		},
	},

	page: {
		in: (href = null) => {
			let preloader = document.querySelector(animate.selector.preloader);
			let shape = preloader.querySelector(".preloader__shape svg");
			let path = preloader.querySelector("path");
			let logo = document.querySelector(animate.selector.logo);

			$("html, body").addClass("js-lock");

			$(preloader).show();

			// animate.center.in(logo);

			anime({
				targets: preloader,
				duration: 1100,
				easing: "easeInOutSine",
				translateY: "0vh",
				complete: function (anim) {
					if (href != null) window.location = href;
				},
			});

			anime({
				targets: shape,
				scaleY: [
					{ value: [0.8, 1.8], duration: 550, easing: "easeInQuad" },
					{ value: 1, duration: 550, easing: "easeOutQuad" },
				],
			});

			anime({
				targets: path,
				duration: 1100,
				easing: "easeOutQuad",
				d: path.getAttribute("data-from"),
			});
		},
		out: () => {
			let logo = document.querySelector(animate.selector.logo);
			let load = sessionStorage.getItem("loaded");
			let preloader = document.querySelector(animate.selector.preloader);
			// if (load == null && $("body").hasClass("is-front-page")) {
			// 	animate.center.out(logo);
			// }

			sessionStorage.setItem("loaded", "true");

			if ($("body").hasClass("is-front-page")) {
				let image = preloader.querySelector(".preloader__image");
				let loadText = preloader.querySelector(".preloader__loaded");
				let text = preloader.querySelector(".preloader__text");

				anime({
					targets: image,
					duration: 400,
					easing: "easeInOutSine",
					translateY: "-25vh",
					opacity: 0,
					delay: 0,
					complete: function (anim) {
						// animate.page.hide()
					},
				});
				anime({
					targets: loadText,
					duration: 400,
					easing: "easeInOutSine",
					translateY: "-25vh",
					opacity: 0,
					delay: 50,
					complete: function (anim) {
						// animate.page.hide()
					},
				});
				anime({
					targets: text,
					duration: 400,
					easing: "easeInOutSine",
					translateY: "-25vh",
					opacity: 0,
					delay: 100,
					complete: function (anim) {
						animate.page.hide(preloader);
					},
				});
			} else {
				animate.page.hide(preloader);
			}
		},

		hide: (preloader) => {
			let shape = preloader.querySelector(".preloader__shape svg");
			let path = preloader.querySelector("path");
			anime({
				targets: preloader,
				duration: 1100,
				// easing: "easeOutCubic",
				easing: "easeInOutSine",
				translateY: "-200vh",
				complete: function (anim) {
					$(preloader).hide();
				},
			});

			anime({
				targets: shape,
				scaleY: [
					{ value: [0.8, 1.8], duration: 550, easing: "easeInQuad" },
					{ value: 1, duration: 550, easing: "easeOutQuad" },
				],
				complete: function (anim) {
					animate.run();
				},
			});

			anime({
				targets: path,
				duration: 1100,
				easing: "easeOutQuad",
				d: path.getAttribute("pathdata:id"),
			});
		},
	},

	text: (el) => {
		el.innerHTML = el.textContent.replace(
			/\S/g,
			"<span class='letter'>$&</span>"
		);

		let target = el
			.closest(animate.selector.textParent)
			.querySelectorAll(".letter");

		anime.timeline({ loop: false }).add({
			targets: target,
			translateX: [40, 0],
			translateZ: 0,
			opacity: [0, 1],
			easing: "easeOutExpo",
			duration: 1200,
			delay: (el, i) => 0 + 30 * i,
		});
	},

	morph: () => {
		// let morphData = $(el).data("morph-to");

		// console.log(el, morphData);

		anime({
			targets: animate.selector.morph,
			d: [
				// {
				// 	value: (el) => {
				// 		let morphData = $(el).data("morph-to");
				// 		return morphData[1];
				// 	},
				// 	duration: (el, i) => 2000,
				// },
				{
					value: (el) => {
						let morphData = $(el).data("morph-to");
						return morphData[0];
					},
					duration: (el, i) => 5000,
				},
				// {
				// 	value: (el) => {
				// 		let morphData = $(el).data("morph-to");
				// 		return morphData[2];
				// 	},
				// 	duration: (el, i) => 3000,
				// },

				{
					value: (el) => el.getAttribute("d"),
					duration: (el, i, l) => 5000,
				},

				// {
				// 	value: (el) => {
				// 		let morphData = $(el).data("morph-to");
				// 		return morphData[2];
				// 	},
				// 	duration: (el, i) => 3000,
				// },
				// {
				// 	value: (el) => {
				// 		let morphData = $(el).data("morph-to");
				// 		return morphData[0];
				// 	},
				// 	duration: (el, i) => 3000,
				// },
				// {
				// 	value: (el) => el.getAttribute("d"),
				// 	duration: (el, i, l) => 3000,
				// },
			],
			loop: true,
			easing: "easeInOutQuad",
			// delay: config.getRandomInt(5, 5000),
			// delay: (el, i) => config.getRandomInt(5, 5000) + 3000 * i,
			update: () => {
				const el = document.getElementById("defs");
				el.style.display = "none";
				el.offsetWidth;
				el.style.display = "block";
			},
		});
	},

	up: (el) => {
		let parent = el.closest(animate.selector.parent);
		let target = parent.querySelectorAll(animate.selector.up);

		let from = $(parent).attr("data-from") ? $(parent).data("from") : 100;
		let plus = $(parent).attr("data-plus") ? $(parent).data("plus") : 50;

		anime.timeline({ loop: false }).add({
			targets: target,
			translateY: [40, 0],
			translateZ: 0,
			opacity: [0, 1],
			easing: "easeOutExpo",
			duration: 1200,
			delay: (el, i) => from + plus * i,
		});
	},

	aos: () => {
		AOS.init({
			duration: 1100,
			// startEvent: "DOMContentLoaded",
			ease: "ease-in-back",
			// startEvent: "load",
			// duration: 1200,
			offset: 500,
			once: true,
			disable: () => {
				let maxWidth = 1025;
				return window.innerWidth < maxWidth;
			},
		});
	},

	links: {
		isActive: null,
		enterTimeout: null,
		color: "#4c4bb5",
		speed: 150,

		init: (el) => {
			if (!$(el).length) return false;

			$(el).each((i, link) => {
				link.innerHTML = link.textContent.replace(
					/\S/g,
					"<span class='letter'>$&</span>"
				);

				let color = $(link).css("color");
				$(link).attr("data-color", color);

				link.addEventListener("mouseenter", () =>
					animate.links.enterHoverInFn(link)
				);
				link.addEventListener("mouseleave", () =>
					animate.links.enterHoverOutFn(link)
				);
			});
		},

		enterHoverInFn: (el) =>
			(animate.links.enterTimeout = setTimeout(() => {
				animate.links.isActive = true;

				let enterLetters = Array.from(el.querySelectorAll("span"));

				let color = $(el).attr("data-hover-color")
					? $(el).data("hover-color")
					: animate.links.color;

				anime.remove(enterLetters);

				anime({
					targets: enterLetters,
					delay: (t, i) => i * 15,
					translateY: [
						{
							value: 15,
							duration: animate.links.speed,
							easing: "easeInQuad",
						},
						{
							value: [-15, 0],
							duration: animate.links.speed,
							easing: "easeOutQuad",
						},
					],
					opacity: [
						{
							value: 0,
							duration: animate.links.speed,
							easing: "linear",
						},
						{
							value: 1,
							duration: animate.links.speed,
							easing: "linear",
						},
					],
					color: {
						value: color,
						duration: 1,
						delay: (t, i, l) => i * 15 + animate.links.speed,
					},
				});
			}, 50)),

		enterHoverOutFn: (el) => {
			clearTimeout(animate.links.enterTimeout);

			if (!animate.links.isActive) return;
			animate.links.isActive = false;

			let enterLetters = Array.from(el.querySelectorAll("span"));
			let color = $(el).attr("data-color");

			anime.remove(enterLetters);

			anime({
				targets: enterLetters,
				delay: (t, i, l) => (l - i - 1) * 15,
				translateY: [
					{
						value: 15,
						duration: animate.links.speed,
						easing: "easeInQuad",
					},
					{
						value: [-15, 0],
						duration: animate.links.speed,
						easing: "easeOutQuad",
					},
				],
				opacity: [
					{
						value: 0,
						duration: animate.links.speed,
						easing: "linear",
					},
					{
						value: 1,
						duration: animate.links.speed,
						easing: "linear",
					},
				],
				color: {
					value: color,
					duration: 1,
					delay: (t, i, l) => (l - i - 1) * 15 + animate.links.speed,
				},
			});
		},
	},

	delay: (i, el) => {
		$(el).css({
			animationDelay: `-${i * config.getRandomInt(2000, 4000)}ms`,
		});
	},

	run: () => {
		// console.log("page run");

		$("html, body").removeClass("js-lock");

		$("body").addClass("is-loaded");

		if (window.innerWidth < 1025) animate.aos();

		// $(window).on("load", () => {
		if (window.innerWidth > 1025) setTimeout(animate.aos, 200);
		// });

		$(".js-random-delay").each(animate.delay);

		// if ($(animate.selector.morph).length) {
		// 	animate.morph();
		// }

		defaults.initScroll();

		animate.links.init(animate.selector.links);

		// $(
		// 	'a[href]:not([href~="#"]):not([target="_blank"]):not([href=""]):not(.js-without-transition)'
		// ).on("click", (e) => {
		// 	if($(e.currentTarget).attr('href').indexOf('#') === -1){
		// 		e.preventDefault();
		// 		console.log('click link')
		// 		let href = $(e.currentTarget).attr("href");
		// 		animate.page.in(href);
		// 	}

		// });

		document.addEventListener("aos:in", (detail) => {
			let $textContainer = $(detail.detail).find(animate.selector.text);
			let $upItem = $(detail.detail).find(animate.selector.up);

			if ($textContainer.length) {
				$textContainer.each((i, el) => {
					animate.text(el);
				});
			}

			if ($upItem.length) {
				$upItem.each((i, el) => {
					animate.up(el);
				});
			}
		});
	},

	imageLoadCheck: (callback) => {

		// let imagesCount = $("picture img[src], img[src$='svg']").length,
		// 	dBody = $("body"),
		// 	percent = 100 / imagesCount, // количество % на одну картинку
		// 	progress = 0,
		// 	imgSum = 5,
		// 	loadedImg = 0;

		// 	console.log("image load check", imagesCount);

		// var img_load = () => {
		// 	progress += percent;
		// 	loadedImg++;

		// 	if (progress >= 100 || loadedImg == imagesCount) {
		// 		if (typeof callback === "function") {
		// 			callback();
		// 		}
		// 	}

		// 	$(".js-loading-progress").html(
		// 		`${Math.min(Math.ceil(progress), 100)}<span>%</span>`
		// 	);
		// };

		// if (imagesCount >= imgSum && imagesCount > 0) {
		// 	for (var i = 0; i < imagesCount; i++) {
		// 		var img_copy = new Image();
		// 		img_copy.src = document.images[i].currentSrc;
		// 		img_copy.onload = img_load;
		// 		img_copy.onerror = img_load;
		// 	}
		// } else {
		// 	if (typeof callback === "function") {
		// 		callback();
		// 	}
		// }

		if(!LOAD_STATUS){

			

			LOAD_PROGRESS += config.getRandomInt(5, 25);

			$(".js-loading-progress").html(
				`${Math.min(Math.ceil(LOAD_PROGRESS), MAX_PAGE_PROGRESS)}<span>%</span>`
			);

			LOAD_TIMER = setTimeout(() => {

				animate.imageLoadCheck(animate.page.out)

			}, config.getRandomInt(20, 200))
		}else{
			clearTimeout(LOAD_TIMER);

			$(".js-loading-progress").html(
				`<span>100%</span>`
			);

			callback()
		}

	},

	init: () => {

		// animate.importLottie()
		$(window).on('load', () => {
			animate.lottie('.js-lottie')
		})

		let load = sessionStorage.getItem("loaded");

		// if (load == null && $("body").hasClass("is-front-page")) {
		// 	let logo = document.querySelector(animate.selector.logo);
		// 	animate.center.set(logo);
		// }

		if ($("body").hasClass("is-front-page")) {
			animate.imageLoadCheck(animate.page.out);
			// setTimeout(() => {
			// 	animate.page.out();
			// }, 500);
		} else {
			animate.page.hide(
				document.querySelector(animate.selector.preloader)
			);
		}
		$(window).on('load', () => {
			LOAD_STATUS = true
		})

		// $(window).on("load", () => {
		// 	setTimeout(() => {
		// 		animate.page.out();
		// 	}, 500);
		// });
	},
};

export { animate };
