import { set } from 'animejs'
import { BreakPoints } from '../constants.js'

var defaults = {
  get siteShadow() {
    const shadow = $('.site-shadow')

    return {
      open() {
        shadow.addClass('is-visible')
      },
      close() {
        shadow.removeClass('is-visible')
      },
      toggle() {
        shadow.toggleClass('is-visible')
      },
      on(eventType, fn) {
        shadow.on(eventType, fn)
      },
      onClick(fn) {
        shadow.on('click', fn)
      },
    }
  },

  get headerCategories() {
    const IS_VISIBLE_MODAL_CLASS = 'is-visible'
    const modal = $('.header-categories')
    const brands = $('.js-header-category-brands')
    const categories = $('.js-header-category-item')
    const categoryContainers = $('.js-header-category-containers')

    function activateContainer(id) {
      categoryContainers.removeClass('active')
      $(`.js-header-category-containers[data-category-id="${id}"]`).addClass('active')
    }

    function activateBrands(id) {
      brands.removeClass('active')
      $(`.js-header-category-brands[data-category-id="${id}"]`).addClass('active')
    }

    function activateCategoryBtn(id) {
      categories.removeClass('active')
      $(`.js-header-category-item[data-category-id="${id}"]`).addClass('active')
    }

    function activateCategory(id) {
      activateContainer(id)
      activateCategoryBtn(id)
      activateBrands(id)
    }

    categories.on('mouseenter', function () {
      const id = $(this).data('category-id')
      activateCategory(id)
    })

    return {
      open: () => {
        modal.addClass(IS_VISIBLE_MODAL_CLASS)
      },
      close: () => {
        modal.removeClass(IS_VISIBLE_MODAL_CLASS)
      },
      toggle: () => {
        modal.toggleClass(IS_VISIBLE_MODAL_CLASS)
      }
    }
  },

  get headerCategoriesBtn() {
    const ACTIVE_BTN_CLASS = 'active'
    let IS_OPEN = false;
    let OPENED = false;

    const btn = $('.js-header-categories-btn')
    const another = $('.header__btn-cart, .header__btn-phone')
    const search_field = $('.js-ss-input')
    const siteShadow = defaults.siteShadow
    const headerCategories = defaults.headerCategories

    function open() {
      OPENED = true;

      
      siteShadow.open()
      headerCategories.open()
      $('.js-ss-default-list').removeClass('is-active')

      setTimeout(() => {
        IS_OPEN = true;
        btn.addClass(ACTIVE_BTN_CLASS)
      }, 300)
    }

    function close(delay = false) {
      
      if (!IS_OPEN && delay) return false;

      btn.removeClass(ACTIVE_BTN_CLASS)
      siteShadow.close()
      headerCategories.close()

      IS_OPEN = false;
      OPENED = false;
    }

    function toggle() {
      // console.log(OPENED, IS_OPEN)

      if (OPENED){
        close(true)
      }else{
        open()
      }
      // btn.toggleClass(ACTIVE_BTN_CLASS)
      // siteShadow.toggle()
      // headerCategories.toggle()
    }

    function isMobile(width) {
      return width <= BreakPoints.tablet_lg
    }

    function initResizeEvent() {
      $(window).on('resize', () => {
        const width = $(window).width()

        if (isMobile(width)) {
          close()
        }
      })
    }

    function init() {
      btn.on('mouseenter', open)
      btn.on('click', toggle)
      siteShadow.on('click', () => close(false))
      another.on('mouseenter', () => close(false))
      search_field.on('focus click keyup', () => close(false))

      // siteShadow.onClick(close)
      initResizeEvent()
    }

    return {
      open,
      close,
      init,
    }
  },

  scrollTo: () => {
    $('.js-scroll-to').click(function () {
      var attrHref = $(this).attr('href')
      var dataHref = $(this).data('href')
      if (dataHref) {
        attrHref = dataHref
      }
      $('html, body').animate(
        {
          scrollTop: $(attrHref).offset().top + 'px',
        },
        {
          duration: 1000,
        },
      )
      return false
    })

    if ($(window).innerWidth() > 580) {
      $(window).scroll(function () {
        let $item = $('.header__wrap')
        let $header = $item.parent()
        let padding = parseFloat($header.css('padding-top'))

        if ($(window).scrollTop() > padding) {
          let height = $item.height()

          $item.addClass('is-fixed')
          $header.height(height)

          // console.log("padding: " + padding, "height : " + height);
        } else {
          $item.removeClass('is-fixed')
          $header.removeAttr('style')
        }
      })
    }
  },

  FAQ: () => {
    $('.faq-list__head').click(function () {
      var item = $(this).closest('.faq-list__item')

      if (item.hasClass('is-active')) {
        item.removeClass('is-active')
        item.find('.faq-list__content').slideUp(300)
      } else {
        $('.faq-list__item.is-active').removeClass('is-active')
        $('.faq-list__content:visible').slideUp(300)
        item.addClass('is-active')
        item.find('.faq-list__content').slideDown(300)
      }
    })

    $('.faq-list__item:first')
      .addClass('is-active')
      .find('.faq-list__content')
      .show()

    $('.js-show-faq').click(function (e) {
      // console.log(e.target)
      if (e.target !== this) return

      $('.faq').removeClass('is-hidden')
      $('.js-hide-faq').addClass('is-active')
      return false
    })

    $('.js-hide-faq').click(function (e) {
      e.preventDefault()
      if (!$(this).hasClass('is-active')) return false

      $('.faq').addClass('is-hidden')
      $(this).removeClass('is-active')
      // return false;
    })
  },

  select: () => {
    $('.select__head').click(function () {
      $(this).closest('.select').toggleClass('is-active')
    })

    $(document).mouseup(function (e) {
      var container = $('.select')

      // if the target of the click isn't the container nor a descendant of the container
      if (
        !container.is(e.target) &&
        container.hasClass('is-active') &&
        container.has(e.target).length === 0
      ) {
        container.removeClass('is-active')
      }
    })

    $('.select-bg').click(function () {
      $('.select').removeClass('is-active')
    })

    $('.select__close').click(function () {
      $('.select').removeClass('is-active')
    })

    $('.select__item').click(function () {
      let value = $(this).data('value')
      let name = $.trim($(this).text())
      let parent = $(this).closest('.select')

      parent.find('.select__input').val(value).trigger('change')
      parent.find('.select__label').text(name)
      parent.removeClass('is-active')

      parent.find('.select__item.is-active').removeClass('is-active')
      $(this).addClass('is-active')
    })
  },

  cart: () => {
    $('.js-product-add').click(function () {
      $('.js-cart-added').addClass('is-active')
      setTimeout(function () {
        $('.js-cart-added').removeClass('is-active')
      }, 3000)
    })

    // $(".btn-cart__toggle").click(function () {
    // 	  	$(".btn-cart__box, .btn-cart__bg").toggleClass("is-active");
    // });

    // $(".btn-cart__bg").click(function () {
    // 	$(".btn-cart__box, .btn-cart__bg").removeClass("is-active");
    // });

    $('.js-open-cart').click(function () {
      $('.mobile__nav-link[data-mobile="#cart"]').click()
    })
  },

  events: () => {
    $('.btn-select__head').click(function () {
      $(this).parent().toggleClass('is-active')
    })

    $(document).mouseup(function (e) {
      var container = $('.btn-select')

      // if the target of the click isn't the container nor a descendant of the container
      if (
        !container.is(e.target) &&
        container.hasClass('is-active') &&
        container.has(e.target).length === 0
      ) {
        container.removeClass('is-active')
      }
    })

    $('.search-form__btn-search').click(function () {
      $(this).closest('.search-form').addClass('is-active')
    })

    $('.search-form__close').click(function () {
      $(this).closest('.search-form').removeClass('is-active')
    })
  },

  initScroll: () => {
    if (window.location.hash) {
      let $item = $(window.location.hash)

      if (!$item.length) return false

      let scrollTo = $item.offset().top

      // document.documentElement.classList.remove("js-lock");
      // document.body.classList.remove("js-lock");

      // console.log("scroll to " + scrollTo)
      // window.scrollTo(0, scrollTo)

      // $('body').scrollTo(scrollTo)
      $('html, body').animate({ scrollTop: scrollTo }, 500)

      // document.documentElement.classList.add("js-lock");
      // document.body.classList.add("js-lock");
    }
  },

  catalog: () => {
    $(".catalog-filter__categories-item-btn").click(function (e) {
      e.stopPropagation();
      $(this).closest('.catalog-filter__tree-item_parent').find(">ul").slideToggle("slow");
      if ($(this).hasClass("active"))
        $(this).removeClass("active");
      else
        $(this).addClass("active");
    });
  },

  init: () => {
    defaults.scrollTo()
    defaults.FAQ()
    defaults.select()
    defaults.events()
    defaults.cart()

    defaults.headerCategoriesBtn.init()
    defaults.catalog()
  },
}

export { defaults }
