import {ajax} from "./ajax";
import {config} from "../config";
import {sliders} from "./sliders";
import {thumbnails} from "./thumbnails";
import { mobile } from "./mobile";

// import { paginate } from "./ajax";
var store = {
    selectors: {
        parent: ".js-product-item",
        item: ".js-product-add",
        count: ".js-product-count",
        attr_input: ".js-attr-input",
        post_id: ".js-post-id",
        variation_id: ".js-variation-id",
        variation_price: ".js-variative-price",
    },

    filter: {
        selectors: {
            sort: ".js-sort-input",
            form: ".js-filter-form",
            button: ".js-filter-send",
            remove: ".js-filter-remove",
            container: ".js-filter-container",
            inputs: "input",
            ppp: ".js-ppp",
            ppp_item: ".js-ppp-item",
            ppp_input: ".js-ppp-input",
            tags_list: ".selected-goods__list",
            tag_item: ".selected-goods__item"
        },

        resetTags() {
            $(`[data-filter-value]`).remove();
            $(`.catalog-scroll input`).prop('checked', false).trigger("change")
            store.filter.toggleClearAll();
        },

        empty: (e) => {
            e.preventDefault()

            // if (!$(e.currentTarget).hasClass('is-visible')) return false;
            //
            // $('.js-cost-from').val($('.js-cost-from').attr('data-value')).trigger('keyup').trigger('blur')
            // $('.js-cost-to').val($('.js-cost-to').attr('data-value')).trigger('keyup').trigger('blur')
            // $('.search-form input').val('')
            //
            // $(store.filter.selectors.remove).removeClass("is-visible");
            //
            // $(store.filter.selectors.form).trigger('submit');
        },

        toggleClearAll() {
            const checkedFilters = $('.catalog-filter__container input:checked')
            const clearAllBtn = $('.js-filter-remove')

            if (checkedFilters.length > 0) {
                clearAllBtn.addClass('is-active');
            } else {
                clearAllBtn.removeClass('is-active');
            }
        },

        change: (el) => {
            const input = $(el);
            const value = input.val()
            const text = input.closest('.checkbox').find('.checkbox__text').text();
            // console.log("change", value);

            $(store.filter.selectors.button).prop("disabled", false);

            $(store.filter.selectors.remove).addClass("is-visible");

            if (input.prop('checked')) {
                addTag()
            } else {
                removeTag()
            }

            store.filter.toggleClearAll()

            function addTag() {
                $(store.filter.selectors.tags_list).prepend(store.templates.tagItem({
                    value,
                    text,
                }));
            }

            function removeTag() {
                $(`[data-filter-value="${value}"]`).remove();
            }
        },

        scrollToCatalog: () => {
            let $catalog = $(store.filter.selectors.container);
            let $header = $('.header');
            let position = $catalog.offset().top - $header.outerHeight();

            $('html, body').animate({scrollTop: position}, 500);

        },

        posts_per_page: (el) => {

            if (!ajax.accept) return false;

            $(store.filter.selectors.ppp_item).removeClass("is-active");
            $(el).addClass("is-active");

            let value = $.trim($(el).text());

            $(store.filter.selectors.form).find('input[name="paged"]').val(1);
            let pg = config.insertParam(location.href, "pg", 1);
            window.history.pushState(null, "", pg);
            // console.log("pg is", pg);

            $(store.filter.selectors.ppp_input).val(value); //.trigger("change");

            store.filter.scrollToCatalog()

            if ($('.js-mobile-open').is(":visible")){
                ajax.getProductsMobile(mobile.realForm);
            }else{
                ajax.getProducts(
                    document.querySelector(store.filter.selectors.form)
                );                
            }

        },


        submit: (e) => {
            e.preventDefault();

            $(store.filter.selectors.button).prop("disabled", true);

            $(store.filter.selectors.form).find('input[name="paged"]').val(1);
            let pg = config.insertParam(location.href, "pg", 1);
            window.history.pushState(null, "", pg);

            ajax.getProducts(e.currentTarget);
        },

        init: () => {
            if (!$(store.filter.selectors.form).length) return false;

            store.filter.selectors.inputs = `input:not(${store.filter.selectors.sort})`;

            $(store.filter.selectors.form).on("submit", store.filter.submit);

            $(store.filter.selectors.tags_list).on("click", store.filter.selectors.tag_item, function () {
                const brandItem = $(this);
                const name = brandItem.data('filter-value');

                const checkbox = $(`input[value="${name}"]`)
                checkbox.prop('checked', false).trigger('change');

                brandItem.remove();

                store.filter.toggleClearAll()
            })

            $(store.filter.selectors.ppp_item).on("click", (e) =>
                store.filter.posts_per_page(e.currentTarget)
            );


            $(store.filter.selectors.button).on('click', e => {
                if (!ajax.accept) return false;
            })

            $(`${store.filter.selectors.form} :input`).on("change", (e) =>
                ajax.getProducts(
                    document.querySelector(store.filter.selectors.form)
                )
            );

            $(`${store.filter.selectors.inputs}:not(.js-product-count)`).on("change", (e) =>
                store.filter.change(e.currentTarget)
            );
        },
    },

    templates: {
        cartItem: (
            basket_id,
            name,
            link,
            image,
            count,
            price,
            regular_price
        ) => {
            return `<div class="btn-cart__item js-basket-item" data-price="${regular_price}" data-id="${basket_id}">
					<input type="hidden" name="count" value="${count}" class="js-quantity-input">
					<a class="btn-cart__preview" href="${link}">
						<picture>
							<source type="image/webp" srcset="${image}.webp">
							<img src="${image}" class="btn-cart__img object-fit" alt="" role="presentation">
						</picture>
					</a>
					<div class="btn-cart__info"><a class="btn-cart__name p" href="${link}"><span class="hover-link">${name}</span></a>
						<div class="btn-cart__price p b">$ <span class="js-item-price">${price}</span> (<span class="js-item-basket-count">${count}</span> pc.)</div>
					</div>
					<div class="btn-cart__delete js-remove-product">
						<svg class="icon icon-close" viewBox="0 0 17 17">
							<use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use>
						</svg>
					</div>
				</div>`;
        },

        tagItem: (props) => {
            const value = props.value;
            const text = props.text;

            return `
                <div class="selected-goods__item" data-filter-value="${value}">
                    <button class="selected-goods__btn-close">
                        <svg class="icon icon-close" viewBox="0 0 17 17">
                            <use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use>
                        </svg>
                    </button>
                    <div class="selected-goods__item-text">${text}</div>
                </div>
            `
        },

        tagClearAll: () => {
            return `
                <div class="selected-goods__clear-all js-filter-remove">
                    <div class="selected-goods__item-text selected-goods__item-text_red">Clear All</div>
                    <button class="selected-goods__btn-close selected-goods__btn-close_red">
                        <svg class="icon icon-close" viewBox="0 0 17 17">
                            <use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use>
                        </svg>
                    </button>
                </div>
            `;
        }
    },

    add: (e) => {
        e.preventDefault();

        let $this = $(e.currentTarget);

        let $parent = $this.closest(store.selectors.parent);

        let ID = $this.data("id");

        // let $variation = $parent.find(store.selectors.variation_id);

        let $amount = $parent.find(store.selectors.count);

        let count = $amount.val();

        let variation_id = $this.attr("data-variation-id") ? $this.attr("data-variation-id") : false;
        // console.log('variation_id: ', variation_id)

        $amount.val("1");

        ajax.addToBasket(ID, count, variation_id);
    },


    update_input: (e) => {
        let $inputs = $(`${store.selectors.attr_input}:checked`);
        let post_id = $(store.selectors.post_id).val();
        let data = {};

        $inputs.each((i, el) => {
            data[$(el).attr('name')] = $(el).val()
        })

        ajax.attrUpdate(post_id, data, (response) => {
            let json = JSON.parse(response);

            let gallery = ".js-product-slider";
            let thumbnails_selector = ".js-product-thumbnails";

            const $stock = $('.product__availability-conatiner');
            let stock_template = "";

            if (json['error']) {
                alert('error')
                return false;
            }

            // console.log(json)

            if (json['in_stock'] == true) {
                
                $('.product__amount, .product__btn').removeAttr('style')

                if (json['stock_count'] !== 0) {
                    stock_template = `<div class="product__availability js-item-up">Only ${json['stock_count']} left In stock</div>`;
                } else {
                    stock_template = `<div class="product__availability js-item-up">In stock</div>`;
                }                
            }else{
                $('.product__amount, .product__btn').hide()
                stock_template = `<div class="product__availability-out js-item-up">Out of stock</div>`;
            }

            $stock.empty().append(stock_template)
            
            $('*[data-variation-id]').attr("data-variation-id", json['id']);
            // $(store.selectors.variation_id).val(json['id']);
            $(store.selectors.variation_price).text(json['price']);

            if (json['images']) {
                sliders.destroy(gallery)
                thumbnails.destroy(thumbnails_selector);

                let large_images = '';
                let small_images = '';

                json['images'].forEach(element => {

                    // large_images += `<div class="product__gallery-slide">
                    // 	<picture>
                    // 		<source type="image/webp" srcset="${element['large']}.webp">
                    // 		<img src="${element['large']}" class="object-fit" title="${element['title']}" alt="${element['alt']}">
                    // 	</picture>
                    // </div>`
                    // data-mobile-options="zoomMode:magnifier"
                    large_images += `<a class="product__gallery-slide MagicZoom js-without-transition" data-mobile-options="zoomMode: off;" target="_blank" data-options="variableZoom: true; lazyZoom: true" href="${element['full']}">
						<img src="${element['large']}" class="object-fit" title="${element['title']}" alt="${element['title']}" />
					</a>`;

                    small_images += `<li class="product__gallery-item">
						<picture>
							<source type="image/webp" srcset="${element['large']}.webp">
							<img src="${element['large']}" class="object-fit">
						</picture>
					</li>`
                });

                $(gallery).empty().html(large_images);
                $(thumbnails_selector).find('ul').empty().html(small_images);

                sliders.build(gallery)
                thumbnails.run(thumbnails_selector)


                if (typeof MagicZoom !== 'undefined') {
                    // console.log('magiczoom updated')
                    $('.product__gallery-slide').each((i, el) => {
                        MagicZoom.start(el)
                    })

                }
            }

        })

    },

    present: {
        node: {
            item: ".js-present",
            delivery: ".js-present-delivery",
            done: ".js-present-done",
            calc: ".js-present-calc",
            label: ".js-present-text"
        },

        state:{
            done: false
        },

        calculate(el, inBasket = -1){

            $(el).show();

            const delivery = Number($(el).data('delivery'));
            const basket = inBasket === -1 ? Number($(el).data('basket')) : inBasket;

            const diff = Math.max((delivery - basket).toFixed(1), 0);
            const perc = Math.min(100 / (delivery / basket), 100);

            const 
                $done = $(el).find(store.present.node.done),
                $delivery = $(el).find(store.present.node.delivery),
                $label = $(el).find(store.present.node.label),
                $calc = $(el).find(store.present.node.calc);

            // console.log('trigger present', diff)

            if (diff > 0){
                // if (store.present.state.done){
                    // console.log('diff > 0 and done - true', $label)
                    $label.html(`Want <span>free delivery</span>? Add <span class="js-present-calc">$${diff}</span> more for free shipping`)
                // }
            }else{
                // if (!store.present.state.done){
                    // console.log('diff <= 0 and done - false', $label)
                    $label.html('Congrats - you get <span>free delivery</span>!')
                    // store.present.state.done = true                    
                // }
            }

            $calc.text(`$${diff}`);
            $done.css('width', `${perc}%`);
            $delivery.text(`$${basket.toFixed(1)}`);

        },

        init(){
            if (!$(store.present.node.item).length) return false;

            $(store.present.node.item).each(function() {
                store.present.calculate(this)
            })
            
        }
    },

    cats: {
        node: {
            input: ".filter-scroll__category-checkbox input",
            item: ".filter-scroll__category-item",
            list: ".filter-scroll__categories-list"
        },

        method: {
            update(){
                const CHECKED = $(this).is(":checked");
                const $parent = $(this).closest(store.cats.node.item);
                const $parents = $parent.parents(store.cats.node.item);
                const $childs = $parent.find(`> ${store.cats.node.list} ${store.cats.node.input}`);

                const childsChecked = $parent.find(`> ${store.cats.node.list} ${store.cats.node.input}:checked`).length;


                if($(this).is(":checked")){

                    $parents.each(function(){
                        const $input = $(this).find(`> ${store.cats.node.input}`);
                        $input.prop('checked', false)
                    })

                    $childs.each(function(){
                        $(this).prop('checked', false)
                    })
                }
                
                if (CHECKED) {
                    $parent.addClass('is-visible')

                    $parent.find(store.cats.node.item).each(function () {
                        const CHILD_CHECKED = $(this).find(`> ${store.cats.node.list} ${store.cats.node.input}:checked`).length;

                        if (CHILD_CHECKED) {
                            $(this).addClass('is-visible')
                        } else {
                            $(this).removeClass('is-visible')
                        }
                    })

                } else {
                    if (!childsChecked) $parent.removeClass('is-visible')

                    $parents.each(function(){
                        const SUBCHILD_CHECKED = $(this).find(`> ${store.cats.node.list} ${store.cats.node.input}:checked`).length;
                        if (!SUBCHILD_CHECKED) $(this).removeClass('is-visible')
                    })

                }

            },            
        },

        init(){
            $(store.cats.node.input).on('change', store.cats.method.update)
        }
    },

    init: () => {
        $(document).on("click", store.selectors.item, store.add);
        $(store.filter.selectors.remove).on("click", store.filter.resetTags);

        $('.catalog-scroll__more-info').on("click", function () {
            const btn = $(this);
            const catalogScroll = btn.closest('.catalog-scroll');
            const wrap = catalogScroll.find('.catalog-scroll__menu-container');

            wrap.addClass('active');
            btn.remove();
        })

        $(store.selectors.attr_input).on('change', store.update_input);

        store.filter.init();

        store.present.init();

        store.cats.init();
    },
};

export {store};
