import { config } from "../config";
import { ajax } from "./ajax";
var input = {

	selectors: {
		item:  ".js-help-item",
		input:  ".js-help-input",
		list:  ".js-help-list",
		subitem: ".js-help-subitem"
	},
	
	methods: {

		clear: (el) => {
			$(el).removeClass('is-active').find(input.selectors.list).empty()
		},

		getStateList: (e) => {

			let $item = $(e.currentTarget);
			let $parent = $item.closest(input.selectors.item)
			let value = $item.val();
			let data = $parent.data('info');
			let results = [];

			if(value){

				for(let key in data) {

					if (data[key].search(new RegExp(value, "i")) != -1) {
						results.push(data[key]);
					}

					// if(data[key].indexOf(value) != -1) {
					// 	results.push(data[key]);
					// }
				}	
				
				if(results.length){

					let ul = '';
					results.forEach(item => {
						ul += `<div class="help-input__item js-help-subitem" data-value="${item}">${item}</div>`;
					});
					
					$parent.addClass('is-active').find(input.selectors.list).empty().append(ul);

				}else{
					input.methods.clear($parent[0]);
				}				

			}else{
				input.methods.clear($parent[0]);
			}
			
		},

		getCityList: (e) => {
			// return
			let $item = $(e.currentTarget);
			let $parent = $item.closest(input.selectors.item)
			let value = $item.val();

			if(value){
				ajax.getCities(value, (result) => {
					let focus = $item.is(":focus");

					if(!focus) return false;

					let json = JSON.parse(result);

					if(json.predictions.length){

						let ul = '';
						json.predictions.forEach(item => {
							// console.log(item.terms[0].value)
							ul += `<div class="help-input__item js-help-subitem" data-value="${item.terms[0].value}" data-state="${item.terms[1].value}">${item.description}</div>`;
						});
						
						$parent.addClass('is-active').find(input.selectors.list).empty().append(ul);

					}else{
						input.methods.clear($parent[0]);
					}
				}, () => {
					input.methods.clear($parent[0]);
				})
			}else{
				input.methods.clear($parent[0]);
			}

		},

		selectSubItem: (e) => {
			let $this = $(e.currentTarget);
			let $parent = $this.closest(input.selectors.item)
			let $input = $parent.find(input.selectors.input)
			let value = $.trim($this.attr('data-value'));

			$input.val(value).trigger('change')

			if($parent.attr('data-for') && $this.attr('data-state')){
				let $for = $($parent.attr('data-for'));
				let $state_input = $for.find(input.selectors.input);
				let state = $this.attr('data-state');
				let state_data = $for.data('info');
				let state_name = state_data[state];

				$state_input.val(state_name)
			}

			input.methods.clear($parent[0]);
		}

	},

	run: (el, type) => {

		if(!$(el).attr('data-info')){
			$(el).find(input.selectors.input)
				.on('keyup', config.delay(input.methods.getCityList, 200))
		}else{
			$(el).find(input.selectors.input)
				.on('keyup', config.delay(input.methods.getStateList, 200))
		}
		
		$(el).on('focusout', function(event) {
			if (this.contains(event.relatedTarget)) return;			
			
			input.methods.clear(this)
		})
	},

	init: () => {
		if(!$(input.selectors.item).length) return false;

		$(input.selectors.item).each((i, el) => input.run(el))

		$(document).on('click', input.selectors.subitem, input.methods.selectSubItem)
	}
}

export {input}