import { defaults } from "./modules/defaults";
import { forms } from "./modules/forms";
import { input } from "./modules/help-input";
import { SS } from "./modules/site-search";
import { CT } from "./modules/category-tab";
// import { ll } from "./modules/lazyload";
import { store } from "./modules/store";
import { news } from "./modules/news";
import { basket } from "./modules/basket";
import { ajax } from "./modules/ajax";
import { pagination } from "./modules/pagination";
import { modals } from "./modules/modals";
import { prlx } from "./modules/parallax";
import { animate } from "./modules/animate";
import { sliders } from "./modules/sliders";
import { thumbnails } from "./modules/thumbnails";
import { wrapSlider } from "./modules/wrapSlider";
import { mobile } from "./modules/mobile";
import { amount } from "./modules/amount";
import { filter } from "./modules/filter";
import { quiz } from "./modules/quiz";
import { config } from "./config";
import { mainCategories } from './modules/main-categories';

var App = () => {};

App.prototype.init = () => {
	defaults.init();
	SS.init();
	CT.init();
	// ll.init();
	store.init();
	ajax.init();
	basket.init();
	news.init();
	sliders.init();
	pagination.init();
	animate.init();
	forms.init();
	input.init();
	prlx.init();
	modals.init();
	mainCategories.init();

	thumbnails.init();
	wrapSlider.init();
	mobile.init();
	amount.init();
	filter.init();
	quiz.init();

	config.log("app init");
};

export { App };
