import { ajax } from "./ajax";
import { store } from "./store";
import { config } from "../config";
import { mobile } from "./mobile";

var pagination = {
	selector: ".js-pagination",
	list: ".pagin__pages",
	// nav: ".pagin__nav",
	link_item: ".pagin__item_number",
	prev_button: ".pagin__item_prev",
	next_button: ".pagin__item_next",
	max: 4,
	step: 2,

	template: {
		number: (href, text, active) => {
			return `<a class="pagin__item pagin__item_number js-without-transition ${
				active ? "is-current" : ""
			}" href="${href}">${text}</a>`;
		},
		list: () => {
			return `<div class="${pagination.list.replace(
				".",
				""
			)} flex flex_vertical"></div`;
		},
		// nav: () => {
		// 	return `<div class="${pagination.nav.replace(
		// 		".",
		// 		""
		// 	)} flex flex_vertical"></div`;
		// },

		// 
		prev: (href) => {
			return `<a class="pagin__item pagin__item_prev" href="${href}"><span class="blobs"><span class="blobs__inner"><svg class="icon icon-arrowLeft" viewBox="0 0 12 19"><use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#arrowLeft"></use></svg><span class="blobs__text">Prev</span></span></span></span></a>`;
		},
		next: (href) => {
			return `<a class="pagin__item pagin__item_next" href="${href}"><span class="blobs"><span class="blobs__inner"><span class="blobs__text">Next</span><svg class="icon icon-arrowRight" viewBox="0 0 12 19"><use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#arrowRight"></use></svg></span></span></a>`;
		},
	},

	destroy: (selector) => {
		$(selector).empty();
	},

	reinit: (selector) => {
		// console.log("pagination reinit");
		pagination.destroy(selector);
		pagination.run(selector);
	},

	refresh: (selector, from, to, current, total) => {
		$(selector).find(pagination.link_item).remove();

		// console.log("refresh", from, to);

		for (var i = from; i <= (to > total ? total : to); i++) {
			let pg = config.insertParam(location.href, "pg", i);

			$(selector).append(pagination.template.number(pg, i, i == current));
		}
	},

	open: (item, callback) => {

		if(!ajax.accept) return false;

		if (item === undefined) return false;

		let $current = $(item);

		let $form = $(store.filter.selectors.form);

		let current_index = parseInt($current.text());

		let parent = $current.closest(pagination.selector)[0];

		let refrence = $(item).prop("href");

		window.history.pushState(null, "", refrence);

		$form.find('input[name="paged"]').val(current_index);

		let list = $current
			.closest(pagination.selector)
			.find(pagination.list)[0];

		let total = $(parent).attr("data-total");

		if (total > pagination.max) {
			// console.log("test data total");
			pagination.refresh(
				list,
				Math.max(1, current_index - pagination.step),
				Math.max(pagination.max, current_index + pagination.step),
				current_index,
				total
			);
		} else {
			pagination.refresh(list, 1, total, current_index, total);
		}

		store.filter.scrollToCatalog()


		if ($('.js-mobile-open').is(":visible")) {
			
			ajax.getProductsMobile(mobile.realForm);
		} else {
			ajax.getProducts(document.querySelector(store.filter.selectors.form));
		}
		
		// ajax[callback](0);
	},

	// клик по кнопке - предыдущая страница
	prev: (item, callback) => {
		if(!ajax.accept) return false;

		let next = $(item)
			.closest(pagination.selector)
			.find(".is-current")
			.prev()[0];

		pagination.open(next, callback);
	},
	// клик по кнопке - следующая страница
	next: (item, callback) => {

		if(!ajax.accept) return false;

		let next = $(item)
			.closest(pagination.selector)
			.find(".is-current")
			.next()[0];

		pagination.open(next, callback);
	},

	// строим пагинацию
	run: (selector) => {
		let count = $(selector).attr("data-total");

		// console.log("run, count is ", count);

		let current = config.getParam(location.href, "pg");

		// console.log("start page is: ", current);

		let current_page =
			current == null || current == "" ? 1 : parseInt(current);
		$(selector).append(pagination.template.prev("#"));

		$(selector).append(pagination.template.list());
		// $(selector).append(pagination.template.nav());

		$(selector).append(pagination.template.next("#"));

		for (
			var i = Math.max(1, current_page - pagination.step);
			i <=
			Math.max(
				Math.min(count, current_page + pagination.step),
				pagination.max > count ? count : pagination.max
			);
			i++
		) {
			let pg = config.insertParam(location.href, "pg", i);

			$(selector)
				.find(pagination.list)
				.append(pagination.template.number(pg, i, i == current_page));
		}
	},

	// инициализируем все 1 раз
	init: () => {
		if (!$(pagination.selector).length) return false;

		$(pagination.selector).each((i, el) => {
			pagination.run(el);
		});

		$(document).on("click", pagination.link_item, (e) => {
			e.preventDefault();
			let callback = $(e.currentTarget)
				.closest(pagination.selector)
				.data("callback");
			pagination.open(e.currentTarget, callback);
		});

		$(document).on("click", pagination.prev_button, (e) => {
			e.preventDefault();
			let callback = $(e.currentTarget)
				.closest(pagination.selector)
				.data("callback");
			pagination.prev(e.currentTarget, callback);
		});
		$(document).on("click", pagination.next_button, (e) => {
			e.preventDefault();
			let callback = $(e.currentTarget)
				.closest(pagination.selector)
				.data("callback");
			pagination.next(e.currentTarget, callback);
		});
	},
};

export { pagination };
