import {store} from "./store";
import {pagination} from "./pagination";
import {basket} from "./basket";
import {sliders} from "./sliders";
import {news} from "./news";

var ajax = {
    productCount: 0,
    oldPostPerPage: 0,
    accept: true,


    getPreloader: (parent) => {
        let $parent = $(parent);
        let $preloader = $parent.find('.preloader__image')

        // console.log('getPreloader', $preloader.length, parent)

        $parent.addClass('is-load')

        if ($preloader.length) {

            $preloader.addClass('is-visible')

        } else {
            let $clone = $('#preloader-image');
            // $parent.prepend($clone.removeAttr('style').addClass('is-visible'))

            $clone.clone().addClass('preloader__image_preload is-visible').removeAttr('style id').prependTo($parent)
        }
    },

    addToBasket: (id, count, variation) => {
        let data = {
            action: "woocommerce_ajax_add_to_cart",
            product_id: id,
            quantity: count,
        };

        if (variation) {
            data['variation'] = variation;
        }

        ajax.send(data, (response) => {
            let json = response; //JSON.parse(response);

            let $total_price = $(".js-total-price");
            let $total_amount = $(".js-total-amount");
            let $product_list = $(".js-product-list");
            let products = "";

            $total_price.text(json.price);

            json.items.forEach((product) => {
                products += store.templates.cartItem(
                    product.basket_id,
                    product.title,
                    product.link,
                    product.image,
                    product.count,
                    product.price,
                    product.regular_price
                );
            });

            $total_amount.text(json.items.length);

            $product_list.empty().append(products);

            const $preset = $(store.present.node.item);
            if ($preset.length) $preset.each((i, el) => store.present.calculate(el, json.price))
        });
    },

    getSearchList: (s, callback) => {
        let data = {
            action: "woocommerce_get_search_list",
            search: s,
        };

        // console.log('getSearchList')

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        });
    },

    checkoutEmail: (email, callback, preloader) => {
        let data = {
            action: "woocommerce_ajax_checkout_email",
            email: email,
        };

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        }, null, preloader);
    },

    returingEmail: (email, callback) => {
        let data = {
            action: "woocommerce_ajax_returing_email",
            email: email,
        };

        let preloader = ".js-returning-preloader";

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        }, null, preloader);
    },

    updateNewsPostList: (formdata = false, search = false, callback) => {
        let data = {
            "action": "woocommerce_ajax_get_posts_list",
        };

        if (formdata) {
            data['tags'] = formdata
        }

        if (search) {
            data['search'] = search
        }

        // console.log('data is-', data)

        let preloader = ".js-blog-preloader";

        ajax.send(data, (response) => {

            let json = JSON.parse(response);

            sliders.destroy(news.node.list)

            $(news.node.list).empty().append(json['content']).attr('data-list', json['ids'])

            let counter = $(news.node.list).data('counter');

            $(counter).find('.owl-counter').html(`<span class="owl-counter-current">1</span> / ${json['count']}`)

            sliders.resize();

            sliders.build(news.node.list);


            if (typeof callback === "function") {
                callback(response);
            }

        }, null, preloader);
    },

    getCities: (search, callback, error_callback) => {
        let data = {
            action: "woocommerce_ajax_get_cities",
            search: search,
        };

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        }, (jqXHR, textStatus, errorThrown) => {

            if (typeof error_callback === "function") {
                error_callback(jqXHR, textStatus, errorThrown);
            }
        });
    },

    getPostData: (id, callback) => {

        let preloader = ".js-modal-blog-preloader";

        let data = {
            action: "woocommerce_ajax_get_post",
            id: id,
        };

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        }, null, preloader);
    },

    checkReturningPassword: (email, password, callback) => {
        let data = {
            action: "woocommerce_ajax_returing_password",
            email: email,
            pass: password,
        };

        let preloader = ".js-returning-preloader";

        ajax.send(data, (response) => {
            if (typeof callback === "function") {
                callback(response);
            }
        }, null, preloader);
    },

    attrUpdate: (post_id, params, success_callback) => {
        let data = {
            action: "woocommerce_get_variative",
            post_id: post_id,
            fields: JSON.stringify(params)
        }

        let preloader = ".js-product-preloader";

        ajax.send(
            data,
            (response) => {
                if (typeof success_callback === "function") {
                    success_callback(response);
                }
            },
            (jqXHR, textStatus, errorThrown) => {
                // console.log('500 error');
                if (typeof error_callback === "function") {
                    error_callback(jqXHR, textStatus, errorThrown);
                }
            },
            preloader
        );
    },

    newOrder: (formdata) => {
        let data = {};

        let preloader = ".js-order-modal-preloader";

        $(formdata).each(function (index, obj) {
            if (obj.value == "") return true;
            data[obj.name] = obj.value;
        });

        data["action"] = "woocommerce_ajax_new_order";

        ajax.send(data, (response) => {

            let json = JSON.parse(response);

            // console.log(json);

            const payment = basket.method.checkPaymentStatus();

            if (payment == "PayPal"){

                if (!json['result']['links'][1]) {
                    alert('eror')
                    return false;
                }                

                // console.log(json, json.result, json['result']['links'][1])

                window.location = json['result']['links'][1]['href'];

            }else{

                if (!json['id']) {
                    alert('eror')
                    return false;
                }

                // var stripe = Stripe('pk_test_51HNd0bB6zOSND3Y75HERerQ46ZEf13D3SUSLAmwQoLkPZ1lHCW1pGKl7vHei1fFa6TgefduZppsGEhPbobwYvTev00fuu49O95');
                var stripe = Stripe('pk_live_51HNd0bB6zOSND3Y7luc66Afxi5zPh41REPzf2mErPWd6f2TcqDO3MfND5U7zHr9jpZ4N4CduBetP7wGFyhH3gis300ncPHo6Zh');
                stripe.redirectToCheckout({sessionId: json['id']});                
            }


        }, null, preloader);
    },

    getTax: (params, success_callback, error_callback, preloader) => {
        let data = Object.assign(
            {
                action: "woocommerce_get_tax",
            },
            params
        );

        ajax.send(
            data,
            (response) => {
                let json = JSON.parse(response);
                $(basket.node.tax).text(json.amount_to_collect);
                basket.method.getTotalWithTax();

                if (typeof success_callback === "function") {
                    success_callback(response);
                }
            },
            (jqXHR, textStatus, errorThrown) => {
                // console.log('500 error');
                // :not([name~="name"])
                // .parent()
                // 'input[name~="shipping"]'
                // .removeClass('valid')
                $('.js-shipping-field').parent().removeClass('valid').addClass('error')

                if (typeof error_callback === "function") {
                    error_callback(jqXHR, textStatus, errorThrown);
                }
            }, preloader
        );
    },

    removeItemAll: () => {
        $(`${basket.node.item}`).remove();

        let data = {
            action: "woocommerce_ajax_remove_all_item",
        };

        ajax.send(data, (response) => {
            // console.log(response);
        });

        basket.method.updateInfoParams();

        // console.log('update price', basket.node.priceWithTax)

        $(basket.node.priceWithTax).html('0')
    },

    removeItem: (id) => {
        $(`${basket.node.item}[data-id="${id}"]`).remove();

        let data = {
            action: "woocommerce_ajax_remove_item",
            product_id: id,
        };

        ajax.send(data, (response) => {
            // console.log(response);

            const $preset = $(store.present.node.item);
            if ($preset.length) $preset.each((i, el) => store.present.calculate(el, response.price))
        });

        basket.method.updateInfoParams();
    },

    updateQuantity: (id, count) => {
        // console.log("updateQuantity");
        let data = {
            action: "woocommerce_ajax_update_quantity",
            product_id: id,
            quantity: count,
        };
        ajax.send(data, (response) => {
            // console.log(response);
        });
    },

    updateProducts: (response) => {
        $(store.filter.selectors.container).removeAttr('style')

        let json = JSON.parse(response);
        let newPostPerPage = parseInt(
            $(store.filter.selectors.ppp_input).val()
        );

        if (
            json["count"] != ajax.productCount ||
            ajax.oldPostPerPage != newPostPerPage
        ) {
            // console.log("изменяем пагинацию");
            let $ppp_input = $(store.filter.selectors.ppp_input);
            let posts_per_page = parseInt($ppp_input.val());

            $(pagination.selector).attr(
                "data-total",
                Math.ceil(json["count"] / posts_per_page)
            );

            // pagination reinit

            pagination.reinit(document.querySelector(pagination.selector));

            ajax.productCount = json["count"];
            ajax.oldPostPerPage = newPostPerPage;
        }

        $(store.filter.selectors.container).empty().append(json["content"]);
    },


    getProductsMobile: (data) => {
        data.action = 'woocommerce_ajax_get_products';
        data.type = 'mobile';

        // console.log('getProductsMobile', data )

        let preloader = ".js-catalog-preloader";

        var subdata = data;

        subdata.action = "woocommerce_ajax_get_products";
        subdata.type = "mobile";
        subdata.posts_per_page = $('.js-ppp-input').val();
        subdata.sort = $('.js-sort-input').val();

    
        ajax.send(subdata, ajax.updateProducts, null, preloader);
    },

    getProducts: (form) => {
        let formdata = $(form).serializeArray();
        let preloader = ".js-catalog-preloader";

        ajax.send(formdata, ajax.updateProducts, null, preloader);

        // $(store.filter.selectors.container).css({
        //     'height': '600rem',
        //     'overflow': 'hidden',
        // });
    },

    send: (params, callback, error_callback, parent = false) => {

        if (!ajax.accept) return false;

        ajax.accept = false;

        if (parent) {
            ajax.getPreloader(parent);
        }

        $.ajax({
            type: "post",
            url: myajax.ajax_url,
            data: params,
            // contentType: "application/json",
            // dataType: "json",
            // processData: false,
            success: function (response) {
                ajax.accept = true;

                if (typeof callback === "function") {
                    callback(response);
                }

                if (parent) {
                    $(parent).find('.preloader__image').removeClass('is-visible')
                    $(parent).removeClass('is-load')
                }

            },
            error: function (jqXHR, textStatus, errorThrown) {
                ajax.accept = true;

                if (parent) {
                    $(parent).find('.preloader__image').removeClass('is-visible')
                    $(parent).removeClass('is-load')
                }

                if (typeof error_callback === "function") {
                    error_callback(jqXHR, textStatus, errorThrown);
                }

            }
        });
    },

    init: () => {
        ajax.productCount = parseInt(
            $(pagination.selector).attr("data-list-count")
        );
        ajax.oldPostPerPage = parseInt(
            $(store.filter.selectors.ppp_input).val()
        );
    },
};
export {ajax};
