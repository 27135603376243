var BreakPoints = {
  desktop_lg: 1440,
  desktop: 1280,
  tablet_lg: 1100,
  tablet: 830,
  mobile_lg: 580,
  mobile: 440,
}

export { BreakPoints }
