import "jquery-ui/ui/widgets/slider";
require("jquery-ui-touch-punch");
import { config } from "../config";

var filter = {
	cost: () => {


		$(".js-cost").each(function () {
			let slider = $(this),
				min = $(this).data("min"),
				max = $(this).data("max");

			slider.find(".js-cost-slider").slider({
				range: true,
				min: min,
				max: max,
				values: [min, max],
				stop: function (event, ui) {
					const $from = slider.find(".js-cost-from");
					// 
					setTimeout(() => {
						$from.trigger("change");
					}, 100)
				},
				slide: function (event, ui) {
					let $from = slider.find(".js-cost-from");
					let $to = slider.find(".js-cost-to");

					// console.log($from, $to, ui.values[0], ui.values[1])

					$from
						.val(config.thousandSeparator(ui.values[0]))
						.attr("data-value", ui.values[0]);
					$to
						.val(config.thousandSeparator(ui.values[1]))
						.attr("data-value", ui.values[1]);
				},
			});

			$(".js-cost-input")
				.on("focus", function () {
					$(this).val($(this).attr("data-value")).trigger("change");
				})
				.on("blur", function () {
					$(this)
						.val(config.thousandSeparator($(this).val()))
						.trigger("change");
				})
				.on("keyup", function () {
					// console.log("on keyup")

					const $parent = $(this).closest('.js-cost');
					const $slider = $parent.find('.js-cost-slider');
					const $from = $parent.find('.js-cost-from');
					const $to = $parent.find('.js-cost-to');

					$(this).attr("data-value", $(this).val());

					// console.log("values", $from.attr("data-value"), $to.attr("data-value"))

					$slider.slider({
						values: [
							$from.attr("data-value"),
							$to.attr("data-value"),
						],
					});
				});
		});
	},

	category: () => {
		$(".filter__category:not(.filter__category_default) .filter__category-head").click(function () {
			$(this).parent().toggleClass("is-active");
		});

		$(document).mouseup(function (e) {
			var container = $(".filter__category:not(.filter__category_default)");

			// if the target of the click isn't the container nor a descendant of the container
			if (
				!container.is(e.target) &&
				container.hasClass("is-active") &&
				container.has(e.target).length === 0
			) {
				container.removeClass("is-active");
			}
		});

		// $(".filter__submenu-link").click(function () {
		// 	let value = $(this).data("value");
		// 	let icon = $(this)
		// 		.parent()
		// 		.find(".filter__submenu-icon svg")
		// 		.clone();

		// 	$(".filter__submenu-link.is-active").removeClass("is-active");
		// 	$(this).addClass("is-active");
		// 	$(this)
		// 		.closest(".has-submenu")
		// 		.find(".filter__submenu-parent .filter__submenu-link")
		// 		.addClass("is-active");
		// 	$(this)
		// 		.closest(".filter__category")
		// 		.removeClass("is-active")
		// 		.find(".filter__category-input")
		// 		.val(value);

		// 	$(".filter__category-select .filter__submenu-link").text(value);
		// 	$(".filter__category-select .filter__submenu-icon").html(icon);
		// });

		$(".filter__category:not(.filter__category_default) .filter__submenu-arrow").click(function () {
			$(this)
				.closest(".has-submenu")
				.toggleClass("is-active")
				.find(".filter__submenu")
				.slideToggle(300);
		});
	},

	init: () => {
		filter.category();
		filter.cost();

		$(".js-filter-show").click(function () {
			$(".filter").fadeIn(500);
			$("body, html").addClass("js-lock");
		});

		$(".js-filter-close").click(function () {
			if ($(window).width() <= 580) {
				$(".filter").fadeOut(500);
				$("body, html").removeClass("js-lock");
			}
		});
	},
};

export { filter };
