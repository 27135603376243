import "magnific-popup";
import { config } from "../config";

var modals = {

	close: (e) => {

		if(!e)
			return false;

		e.preventDefault();

		config.log('close modal');

		$.magnificPopup.close();	

	},

	open: (e, modal) => {
		e = e || false;

		if (e) e.preventDefault();

		modal =
			modal ||
			(e != false
				? $(e.currentTarget).attr("href")
					? $(e.currentTarget).attr("href")
					: $(e.currentTarget).data("modal")
				: e);

		if (!modal) return false;

		let open = $.magnificPopup.instance.isOpen;

		if (open) {
			var mfp = $.magnificPopup.instance;
			
			mfp.items = [];

			// modify the items array (push/remove/edit)
			mfp.items.push({
				src: modal,
				type: "inline",
			});

			// call update method to refresh counters (if required)
			mfp.updateItemHTML();
		} else {

			$.magnificPopup.open(
				{
					tClose: 'Закрыть',
					removalDelay: 600,
					fixedContentPos: true,
					fixedBgPos: true,
					overflowY: 'hidden',			
					closeMarkup: '<div class="modals__close close js-close-modal"><svg class="icon icon-close" viewBox="0 0 612 612"><use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use></svg></div>',
					// closeMarkup: '<div class="modals__column"><div class="modals__sticky"><div class="modals__close close js-close-modal"><svg class="icon icon-close" viewBox="0 0 612 612"><use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use></svg></div></div></div>',
					mainClass: 'css-modal-animate',				
					items: {
						src: modal,
						type: 'inline'
					},
					callbacks: {
						beforeOpen: () => {
						},
		
						close: () => {
							if(modal == "#post")
								window.history.pushState(null, null, window.location.href.split("?")[0]);
						}
					}
				},
				0
			);
		}
	},	


	init: (e) => {

		
		$(document).on('click', '.js-close-modal', modals.close);

		$(document).on('click', '.js-modal', modals.open);
		
		/*$(window).on('load', function() {
			
			$.magnificPopup.open({
				tClose: 'Закрыть',
				removalDelay: 600,
				fixedContentPos: true,
				fixedBgPos: true,
				overflowY: 'hidden',			
				closeMarkup: '<div class="modals__close close js-close-modal"><svg class="icon icon-close" viewBox="0 0 17 17"><use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use></svg></div>',
				mainClass: 'css-modal-animate',				
				items: {
					src: '#order',
					type: 'inline'
				},
				callbacks: {
					beforeOpen: () => {
					},

					beforeClose: () => {
					}
				}
			}, 0);
			
		});*/

	}

};


export { modals };