var quiz = {
	init: () => {

		function update_price() {
			let arrPrice = [];

			if(this){
				let subprice = $(this).data("price")
				$(".js-price").html(subprice);
			}

			arrPrice = $(
				".quiz__step:visible .js-quiz-checkbox:checked, .quiz__step:visible .js-quiz-radio:checked"
			)
				.map(function () {
					var price = parseInt($(this).data("price"));

					if(Number.isNaN(price)) return;

					// console.log(`${$(this).attr('name')} price is: `, price)
					

					return price;
				})
				.get();

			

			function arraySum(array) {
				var sum = 0;
				for (let i = 0; i < array.length; i++) {
					sum += array[i];
				}
				$(".js-total-price").html(sum);
			}
			arraySum(arrPrice);
		}

		function change_services(){
			let $input = $(".js-change-service:checked");
			if ($input.val() == "Pet sitting") {

				$(".js-dog-walking").hide()
				$(".js-pet-sitting").show()

			} else {
				
				$(".js-pet-sitting").hide()
				$(".js-dog-walking").show()

			}
		}

		$(".js-next-step").click(function () {
			let values = $('.quiz__steps label:visible input').serializeArray();
			let $container = $('.quiz__hidden');
			let inputs = "";

			values.forEach(element => {
				inputs += `<input type="hidden" name="${element.name}" value="${element.value}" />`
			});

			$container.empty().append(inputs);

			$(".quiz__services").hide();
			$(".quiz__form").fadeIn(500);
			$(".quiz__dog").addClass("is-active");


		});

		$(".js-back-step").click(function () {
			$(".quiz__form").hide();
			$(".quiz__services").fadeIn(500);
			$(".quiz__dog").removeClass("is-active");
		});

		$(".js-change-service").on("change", change_services);

		$(".js-quiz-radio").change(function () {
			let name = $(this).attr("name");
			let text = $(this).val();
			let price = $(this).data("price");

			$(".js-" + name).html(text);
		});



		$(".js-quiz-checkbox").change(function () {
			let arrText = [];

			arrText = $(".js-quiz-checkbox:checked")
				.map(function (currElement, arr) {
					let length = $(".js-quiz-checkbox:checked").length - 1;

					if (currElement == length) {
						return $(this).val() + ".";
					} else {
						return $(this).val() + ", ";
					}
				})
				.get();

			$(".js-extra").html(arrText);
		});


		change_services()


		$(".quiz__step:visible .js-quiz-radio:checked").each((i, el) => {
			let name = $(el).attr("name");
			let text = $(el).val();

			$(".js-" + name).html(text);	
		})		

		$(".js-quiz-checkbox, .js-quiz-radio").on("change", update_price);
	},
};

export { quiz };
