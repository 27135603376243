var config = {
    debug: true,

    animationEnd:
        "animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd",

    transitionEnd: "transitionend webkitTransitionEnd oTransitionEnd",

    container: $("html, body"),

    getRandomInt: (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    log: (...args) => {
        if (!config.debug) return false;

        console.log(...args);
    },

    thousandSeparator: (str) => {
        var parts = (str + "").split("."),
            main = parts[0],
            len = main.length,
            output = "",
            i = len - 1;

        while (i >= 0) {
            output = main.charAt(i) + output;
            if ((len - i) % 3 === 0 && i > 0) {
                output = " " + output;
            }
            --i;
        }

        if (parts.length > 1) {
            output += "." + parts[1];
        }
        return output;
    },    

    URLToArray: (url) => {
        var request = {};
        var pairs = url.substring(url.indexOf("?") + 1).split("&");
        for (var i = 0; i < pairs.length; i++) {
            if (!pairs[i]) continue;
            var pair = pairs[i].split("=");
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
        return request;
    },

    ArrayToURL: (array) => {
        var pairs = [];
        for (var key in array)
            if (array.hasOwnProperty(key))
                pairs.push(
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(array[key])
                );

        return pairs.join("&");
    },

    numberWithSpaces: (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    delay: (fn, ms) => {
        let timer = 0;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(fn.bind(this, ...args), ms || 0);
        };
    },

    throttle: (func, ms) => {

        let isThrottled = false,
            savedArgs,
            savedThis;

        function wrapper() {

            if (isThrottled) {
                savedArgs = arguments;
                savedThis = this;
                return;
            }

            func.apply(this, arguments);

            isThrottled = true;

            setTimeout(function () {
                isThrottled = false;
                if (savedArgs) {
                    wrapper.apply(savedThis, savedArgs);
                    savedArgs = savedThis = null;
                }
            }, ms);
        }

        return wrapper;
    },

    guidGenerator: () => {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1);
        };
        return (
            S4() +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            S4() +
            S4()
        );
    },

    getParam: (url_string, param) => {
        let url = new URL(url_string);
        let value = url.searchParams.get(param);

        return value;
    },

    insertParam: (url, paramName, paramValue) => {
        if (paramValue == null) {
            paramValue = "";
        }
        var pattern = new RegExp("\\b(" + paramName + "=).*?(&|#|$)");
        if (url.search(pattern) >= 0) {
            return url.replace(pattern, "$1" + paramValue + "$2");
        }
        url = url.replace(/[?#]$/, "");
        return (
            url +
            (url.indexOf("?") > 0 ? "&" : "?") +
            paramName +
            "=" +
            paramValue
        );
    },
};

export {config};
