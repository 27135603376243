var CT = {

	node: {
		selecor: ".js-categorytab",
		slider: ".js-categorytab-slider",
		item: ".js-categorytab-item",
		nav: ".js-categorytab-nav",

	},

	mods: {
		active: "is-active"
	},

	methods: {
		change: (el, slug) => {
			let $slider = $(el).find(CT.node.slider);

			for (let i=0; i < $(CT.node.item).length; i++) {
				$slider.trigger('remove.owl.carousel', [i])
			}

			let data = $slider.data('slides');

			// console.log(data[slug]);
			
			data[slug].forEach(slide => {
				$slider.trigger('add.owl.carousel', 
					[`<a class="category__item js-categorytab-item" href="${slide.link}">
						<div class="category__inner">
							<div class="category__info">
								<div class="category__item-title h4">${slide.title}</div>
								<div class="category__arrow-link arrow-link">
									<div class="arrow-link__text">More</div>
									<div class="arrow-link__icon">
										<svg class="icon icon-arrowRight" viewBox="0 0 12 19">
											<use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#arrowRight"></use>
										</svg>
									</div>
								</div>
							</div>
							${(slide.image ? `<div class="category__img"><img alt="" src="${slide.image}"></div>` : "")}
						</div>
					</a>`]
				);
			});

			$slider.trigger('refresh.owl.carousel')
		}
	},

	run: (el) => {

		let $nav = $(el).find(CT.node.nav)

		$nav.on('click', function () {
			$nav.removeClass(CT.mods.active)
			$(this).addClass(CT.mods.active)
			CT.methods.change(el, $(this).data('name'))
		})
	},

	init: () => {
		if(!$(CT.node.selecor).length) return false;

		$(CT.node.selecor).each((i, el) => {
			CT.run(el)
		})
	}

}

export {CT}