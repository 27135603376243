import { ajax } from "./ajax";
import { modals } from "./modals";
import { sliders } from "./sliders";

var news = {

	templates: {
		tag: (name, id) => {
			return `<div class="blog__tag tag ${news.node.tagsItem.replace('.','')}" data-id="${id}">
				<div class="tag__text">${name}</div>
				<div class="tag__close">
					<svg class="icon icon-close" viewBox="0 0 17 17">
						<use xlink:href="/wp-content/themes/minsom/app/icons/sprite.svg#close"></use>
					</svg>
				</div>
			</div>`;
		}
	},

	node: {
		parent: ".js-news",
		tagsForm: ".js-tags-form",
		searchForm: ".js-search-form",
		openButton: ".js-open-button",
		tagsList: ".js-tags-list",
		tagsItem: ".js-tag-item",
		list: ".js-news-list",
		nav: ".js-nav-button",
		removeAllButton: ".js-remove-all"

	},

	methods: {

		change: (dir) => {
			let modal = "#post";
			let $container = $(modal)
			let id = $container.attr('data-id');

			let $list = $(news.node.list);
			let dataList = $list.attr('data-list').split(",");
			let arrayIndex = dataList.indexOf(id);
			// console.log(id, dataList, arrayIndex)
			let next = 0;			
			

			if(dir == 'next'){

				if(arrayIndex != dataList.length - 1){
					next = arrayIndex + 1;
				}

			}else{
				if(arrayIndex != 0){
					next = arrayIndex - 1;
				}else{
					next = dataList.length - 1;
				}
			}
			
			let link = $(`a[data-id="${dataList[next]}"]:first`)[0];
			// console.log(`arrayIndex: ${arrayIndex}`,`next: ${next}`,`dataList:`,dataList,`curID: ${id}`,`nextID: ${dataList[next]}`)

			news.methods.get(dataList[next], link)

			// let count = $('.blog__slider .owl-item:not(.cloned)').length;
			// let $current = $(`.blog__preview[data-id="${id}"]`).closest('.owl-item');
			// let next = 0;
			// let index = $current.index() + 1;
			// if(dir == 'next'){
			// 	if(index != count){
			// 		next = $current.next().index();
			// 	}
			// }else{
			// 	if(index > 1){
			// 		next = $current.prev().index();

			// 	}else{
			// 		next = count -1;
			// 	}
			// }

			

			// let $next = $('.blog__slider').find('.owl-item').eq(next);
			// let $button = $next.find('.blog__preview');
			// let newID = $button.attr('data-id')

			// console.log("dir, index, count, next: ", dir, index, count, next, $next)

			// $button.trigger('click')

			// $container.attr('data-id', newID)

		},

		open: () => {
			let url = new URL(window.location.href);
			let article = url.searchParams.get("article");
		
			if(!article)
				return false;
		
			let $item = $(`a[href*="${article}"]:first`);
		
		
			$item.trigger('click');

			modals.open(false, "#post");

		},

		get: (id, el = false) => {

			let modal = "#post";
			let $container = $(modal)

			let old_id = $container.attr('data-id');

			$container.attr('data-id', id)

			modals.open(false, modal);


			if(el){
				let url = $(el).attr('href');
				window.history.pushState(null, null, url);
			}
			
			if(old_id != id){
				ajax.getPostData(id, (data) => {

					let json = JSON.parse(data);
					let gallery = ".modals__gallery";

					// modals
					$container.find('.modals__title').text(json.title);
					$container.find('.modals__date').text(json.date);
					$container.find('.modals__content').html(json.content);
					

					sliders.destroy(gallery)

					if(json.tags){
						let tags = '';

						json.tags.forEach(element => {
							tags += `<div class="modals__tag tag">${element}</div>`;
						});

						$container.find('.modals__tags').html(tags);
					}
					
					if(json.gallery){
						let images = '';

						json.gallery.forEach(element => {

							images += `<div class="modals__img">
								<picture>
									<source type="image/webp" srcset="${element}.webp">
									<img src="${element}">
								</picture>
							</div>`;
							
						});

						$container.find(gallery).empty().html(images);

						sliders.build(gallery)
					}

				})				
			}



		},
		
		searchForm: (el) => {
			let $form = $(el);
			let $tagsForm = $(news.node.tagsForm);
			let $input = $form.find('input');
			let search = $.trim($input.val());

			// console.log('search is-', search)

			// $input.val('')

            $tagsForm.find('input[type="checkbox"]:checked').prop('checked', false)
			
			news.methods.tagsRemoveAll(false)
			news.methods.tagsCountCheck()

			// news.methods.tagsUpdate()

			ajax.updateNewsPostList(false, search)
		},

		tagsCountCheck: () => {
			let count = $(news.node.tagsItem).length

			// console.log('count is', count)

			if(count == 0){
				$(news.node.tagsList).hide()
			}else{
				$(news.node.tagsList).show()
			}
		},
 
		tagsUpdate: () => {
			let tags = '';

			$(news.node.tagsForm).find('input:checked').each((i, el) => {

				let id = $(el).val();
				let name = $(el).parent().find('.checkbox__text').text()

				tags += news.templates.tag(name, id)

			})

			news.methods.tagsCountCheck()

			$(news.node.tagsList).remove(news.node.tagsItem).prepend(tags)

			
		},

		tagRemove: (el = null, id) => {
			let $item = el ? $(el) : $(`${news.node.tagsItem}[data-id=${id}]`);
			let form = document.querySelector(news.node.tagsForm);

			$item.remove()
			
			$(form).find(`input[value="${id}"]`).prop('checked', false)

			news.methods.tagsCountCheck()

			news.methods.update(form);
		},

		tagsRemoveAll: (update = true) => {
			let $items = $(`${news.node.tagsItem}`);
			let form = document.querySelector(news.node.tagsForm);

			$items.remove()
			
			$(form).find(`input:checked`).prop('checked', false)

			news.methods.tagsCountCheck()

			$(news.node.tagsForm).removeClass('is-active')

			if(update) news.methods.update(form);
		},

		update: (el) => {
			let $form = $(el);
			let params = [];
			
            $form.find('input[type="checkbox"]:checked').each((i, el) => {
				params.push($(el).val())
			});
			
			$(news.node.tagsForm).removeClass('is-active')

			news.methods.tagsUpdate()

			news.methods.tagsCountCheck()

			ajax.updateNewsPostList(params)
		}
	},

	init: () => {
		if(!$(news.node.parent).length) return false;

		$(window).on('load', news.methods.open)

		$(document).on('click', news.node.openButton, e => {
			e.preventDefault();

			let item = e.currentTarget
			let id = $(item).attr('data-id')

			news.methods.get(id, item)
		})

		$(document).on('click', news.node.tagsItem, e => {
			e.preventDefault();

			let id = $(e.currentTarget).attr('data-id')

			news.methods.tagRemove(e.currentTarget, id)
		})

		$(news.node.removeAllButton).on('click', e => {
			e.preventDefault();

			news.methods.tagsRemoveAll()
		})


		$(news.node.tagsForm).on('submit', (e) => {
			e.preventDefault();

			news.methods.update(e.currentTarget)
		})

		$(news.node.searchForm).on('submit', (e) => {
			e.preventDefault();

			news.methods.searchForm(e.currentTarget)
		})

		$(document).on('click', news.node.nav, e => {
			e.preventDefault();

			let dir = $(e.currentTarget).hasClass('is-next-dir') ? "next" : "prev";

			news.methods.change(dir)
		})

		
	}
}

export {news};