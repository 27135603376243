import { config } from "../config";
import { ajax } from "./ajax";
var SS = {
	node: {
		selector: ".js-site-search",
		list: ".js-ss-list",
		item: ".js-ss-item",
		input: ".js-ss-input",
		defaultList: ".js-ss-default-list"
	},

	mods: {
		active: "is-active"
	},

	methods: {

		showList: (search) => {
			$(search).find(SS.node.list).addClass(SS.mods.active)
		},

		hideList: (search) => {
			$(search).find(SS.node.list).removeClass(SS.mods.active)
		},

		editWords: (search, word) => {
			$(search).find(SS.node.item).each((i, el) => {
				let text = $.trim($(el).text())

				let sep = word.split(' ').join('|')

				let regex = new RegExp("(" + sep + ")", "gi");

				text = text.replace(regex, "<b>$1<\/b>")

				$(el).html(text)
			})
		},

		change: (e) => {
			let text = $(e.currentTarget).val();
			let $search = $(e.currentTarget).parent();
			let $list = $search.find(`${SS.node.list} ul`)
			let $input = $search.find(SS.node.input)

			if(!text) return false;

			// ajax
			// console.log("searh:" + text)


			// SS.methods.showList($search);
			ajax.getSearchList(text, (response) => {
				let data = JSON.parse(response);

				$list.empty()

				if(!data.list || data.list.length == 0) return false;

				let search_list = "";

				data.list.forEach(str => {
					search_list += `<li class="product-search__list-item"><a class="product-search__list-link p js-ss-item" href="${str.link}">${str.title}</a></li>`
				});

				$list.append(search_list)

				SS.methods.editWords($search[0], text)

				if($input.is(':focus')){
					SS.methods.showList($search);
				}
			})

		}
	},

	run: (el) => {
		$(el).find(SS.node.input).on({
			keyup: config.delay(SS.methods.change, 500),
			input: (e) => {
				SS.methods.editWords(el, $(e.currentTarget).val())

				if (e.target.value === '') {
					$('.js-ss-default-list').addClass('is-active')
				} else {
					$('.js-ss-default-list').removeClass('is-active')
				}
			},
			focus: (e) => {
				if (e.target.value === '') {
					$('.js-ss-default-list').addClass('is-active')
				} else {
					$('.js-ss-default-list').removeClass('is-active')
				}
			},
			blur: () => {
				$('.js-ss-default-list').removeClass('is-active')
			}
		})

		$(document).on("mouseup", function(e) {
			let container = $(el);
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				SS.methods.hideList(el)
			}
		});

	},

	init: () => {
		if(!$(SS.node.selector).length) return false;

		$(SS.node.selector).each((i, el) => {
			SS.run(el)
		})
	}
}

export {SS}
