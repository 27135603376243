import { config } from "../config";
import { ajax } from "./ajax";
import { modals } from "./modals";
var basket = {
	node: {
		parent: ".js-basket",
		form: ".js-order-form",
		formButton: ".js-order-submit",
		minicart: ".js-minicart",
		minicart_amount: ".js-total-amount",
		item: ".js-basket-item",
		quantity: ".js-total-quantity",
		tax: ".js-total-tax",
		price: ".js-total-price",
		shipping: ".js-total-shipping",
		payTriggerButton: ".js-pay-trigger",
		payButton: ".js-pay-button",
		priceWithTax: ".js-total-price-with-tax",
		remove: ".js-remove-product",
		removeAll: ".js-remove-all-product",
		quantityButton: ".js-quantity-button",
		quantityInput: ".js-quantity-input",
		itemPrice: ".js-item-price",
		itemCount: ".js-item-basket-count",
		createOrder: ".js-new-order",
		confirm: ".js-confirm",
		returningButton: ".js-returning-button",
		returningEmail: ".js-returning-email",
		duplicateInput: ".js-duplicate-value",
		returningPasswordButton: ".js-returning-password-button",
		returningPasswordField: ".js-returning-password-input",
		returningPasswordEdit: ".js-returning-edit",
		paymentInput: ".js-payment-input"
	},

	method: {
		duplicateValue: (e) => {
			let $this = $(e.currentTarget);
			let $to = $($this.data("to"));

			$to.val($this.val());
		},
		payCheck: (e) => {
			e.preventDefault()

			// console.log('payCheck')

			let window_scroll = $(window).scrollTop();
			let offset = $($(e.currentTarget).attr('href')).offset().top;

			if(window_scroll < offset){
				$('html, body').animate({
					scrollTop: offset
				}, 500);
			}

			$(`${basket.node.payTriggerButton}:visible`).trigger('click');
		},

		checkPaymentStatus: () => {
			const $field = $(`${basket.node.paymentInput}:checked`);

			return $field.val()
		},

		checkShipping: () => {
			let $item = $(basket.node.shipping);
			let limit = parseFloat($item.attr('data-limit'));
			let cost = parseFloat($item.attr('data-price'));
			let total_price = parseFloat($(`${basket.node.price}:first`).text())

			// console.log('shipping check total price:', total_price)

			if(total_price > limit){
				$item.text('0')
			}else{
				$item.text(cost)
			}
		},

 		getTotalWithTax: () => {
			let $tax = $(basket.node.tax);
			let $total = $(`${basket.node.price}:first`);
			let $shipping = $(basket.node.shipping);

			let tax_val = parseFloat($tax.text());
			let price_val = parseFloat($total.text());
			let shipping = parseFloat($shipping.text());

			let value = (parseInt((tax_val + price_val + shipping) * 100)) / 100;

			$(basket.node.priceWithTax).text(value)
		},
		orderSubmit: (e) => {
			e.preventDefault();
			$(`${basket.node.form}.is-active`).trigger("submit");
		},
		returningNext: () => {
			$(".order__returing-step:visible")
				.not(".order__returing-step:last-child")
				.hide()
				.next()
				.fadeIn(500);
		},
		slideNext: ($el) => {
			$el.closest(".order__section")
				.addClass("is-complete is-sucsess")
				.next()
				.removeClass("is-closed");
		},
		returingEmail: (e = false) => {
			if (e) e.preventDefault();

			let $email = $(basket.node.returningEmail);
			let email_val = $email.val();

			ajax.returingEmail(email_val, (response) => {
				let json = JSON.parse(response);

				if (json["exist"]) {
					basket.method.returningNext();
				} else {
					alert("This email is not in the database");
				}
			});
		},
		checkPassword: (e = false) => {
			if (e) e.preventDefault();

			let $email = $(basket.node.returningEmail);
			let $pass = $(basket.node.returningPasswordField);
			let $edit = $(basket.node.returningPasswordEdit);

			let email_val = $email.val();
			let pass_val = $pass.val();

			ajax.checkReturningPassword(email_val, pass_val, (response) => {
				let json = JSON.parse(response);

				if (json["exist"] && !json["error"]) {
					// basket.method.returningNext();
					// console.log("пароль: ", json["code"]);

					for (let ind in json["info"]) {
						let $input = $(`.order__addr input[name*="${ind}"]`);
						let $parent = $input.parent();
						$input.val(json["info"][ind][1]);
						if ($parent.hasClass("select")) {
							$parent
								.find(".select__label")
								.text(json["info"][ind][1]);
						}
					}

					if ($edit.is(":checked")) {
						// console.log("edit order field");
						$(".order__returing-steps").fadeOut(300);
						$(
							".order__addr .order__section_adress:first-child"
						).removeClass("is-closed");
					} else {
						// console.log("open modal order");
						basket.method.buildModalFields(json["info"]);
					}
				} else {
					alert("This email is not in the database or the password is incorrect");
				}
			});
			// let formData = [
			// 	{
			// 		name: "is_returning",
			// 		value: "true",
			// 	},
			// 	{
			// 		name: "user_email",
			// 		value: email_val,
			// 	},
			// ];
			// ajax.newOrder(formData);
		},

		buildModalFields: (data) => {
			let list = "";
			let $container = $(".js-info-container");
			let template = (name, val) => {
				return `<div class="modals__order-item">
					<div class="modals__order-column">
						<div class="modals__order-label">${name}</div>
						<div class="modals__order-text js-first-name">${val}</div>
					</div>
				</div>`;
			};

			if (typeof data == "string") {
				$(`${basket.node.form}.is-active ${data}`).each((i, el) => {
					let label = $(el).closest(".order__column, .order__pay-container").find("label")[0];
					let label_hidden = $(label).attr("data-help")
						? " (" + $(label).data("help") + ")"
						: "";

					if ($(el).val() == "") return true;

					list += template(
						$.trim($(label).text()) + label_hidden,
						$(el).val()
					);
				});
			} else {
				for (let item in data) {
					list += template(data[item][0], data[item][1]);
				}
			}

			let $duplicate_input = $(".js-dublicate-adress");

			if ($duplicate_input.is(":checked")) {
				list += `<div class="modals__order-item">
					<div class="modals__order-column">
						<div class="modals__order-label">Billing</div>
						<div class="modals__order-text js-first-name">My Billing Information is the same as My Shipping Information</div>
					</div>
				</div>`;
			}

			$container.empty().append(list);

			modals.open(false, "#order");
		},

		confirmSlide: (e) => {
			e.preventDefault();

			let $this = $(e.currentTarget);
			let $form = $this.closest("form");
			let $lastItem = $(".order__section-inside:visible");

			$form.validate();
			let valid = $form.valid();

			if (!valid) return;

			if ($lastItem.hasClass("js-checkout-email")) {
				let email = $lastItem.find(".js-email-input").val();

				ajax.checkoutEmail(email, (response) => {
					let json = JSON.parse(response);

					if (!json["exist"]) {
						basket.method.slideNext($lastItem);
					} else {
						alert(
							'Email is already registered, select "I am a returing customer" or enter a new Email'
						);
					}
				}, ".js-checkout-email-preloader");
			} else if ($lastItem.hasClass("js-checkout-shipping")) {
				let $fields = $lastItem.find("input");
				let params = {};

				$fields.each((i, el) => {
					params[$(el).attr("name")] = $(el).val();
				});
				// basket.method.buildModalFields(`input[name^="fields"]`);
				ajax.getTax(params, () => {
					basket.method.slideNext($lastItem);
				}, null, ".js-checkout-tax-preloader");

				// ret
			} else if ($lastItem.hasClass("js-checkout-final")) {
				basket.method.buildModalFields(`input[name^="fields"][type!="radio"], input[name^="fields"][type="radio"]:checked`);
			} else {
				basket.method.slideNext($lastItem);
			}
		},

		getTotalQantity: () => {
			let quantity = 0;
			$(basket.node.parent).find(basket.node.quantityInput).each((i, el) => {
				quantity += parseInt($(el).val());
				// console.log();
			});

			return quantity;
		},
		getTotalPrice: () => {
			let parent = $(basket.node.parent).length
				? basket.node.parent
				: '.header__btn-cart' + basket.node.minicart;

			let $items = $(parent).find(basket.node.item);

			let data = {};

			data.price = 0;
			data.count = $items.length;

			$items.each((i, el) => {
				let price = parseFloat($(el).attr("data-price"));
				let count = parseInt(
					$(el).find(basket.node.quantityInput).val()
				);

				// console.log("price / count - ", price, count);

				data.price += price * count;
			});

			return data;
		},

		updateInfoParams: () => {
			let total_quantity = basket.method.getTotalQantity();
			let total_price = basket.method.getTotalPrice();

			$(basket.node.minicart_amount).text(total_price.count);
			$(basket.node.price).text(total_price.price.toFixed(2));
			$(basket.node.quantity).text(total_quantity);

			basket.method.checkShipping()

			basket.method.getTotalWithTax()
		},
	},

	init: () => {
		// if (!$(basket.node.parent).length) return false;

		$(document).on("click", basket.node.removeAll, ajax.removeItemAll);

		$(basket.node.payButton).on('click', basket.method.payCheck)

		$(document).on("click", basket.node.remove, (e) => {
			let $this = $(e.currentTarget);
			let $item = $this.closest(basket.node.item);
			let id = $item.data("id");

			ajax.removeItem(id);

			
		});

		$(basket.node.formButton).on("click", basket.method.orderSubmit);

		$(basket.node.confirm).on("click", basket.method.confirmSlide);
		$(basket.node.returningButton).on("click", basket.method.returingEmail);

		$(basket.node.returningPasswordButton).on(
			"click",
			basket.method.checkPassword
		);

		$(basket.node.duplicateInput).on(
			"change",
			basket.method.duplicateValue
		);

		// $(document).on("click", basket.node.createOrder, (e) => {
		// 	ajax.newOrder();
		// });

		$(basket.node.quantityInput)
			.on("change", (e) => {
				// обновляем данные на странице
				let $this = $(e.currentTarget);
				let $parent = $this.closest(basket.node.item);
				let parent_id = $parent.data("id");
				let $items = $(`${basket.node.item}[data-id="${parent_id}"]`);
				let $itemsPrice = $items.find(basket.node.itemPrice);
				let $itemsCount = $items.find(basket.node.itemCount);
				let val = $this.val();
				let price = val * parseFloat($parent.attr("data-price"));

				$itemsPrice.text(price.toFixed(2));
				$itemsCount.text(val)
				basket.method.updateInfoParams();
			})
			.on(
				"change",
				config.delay((e) => {
					// делаем ajax запрос с задержкой
					let $this = $(e.currentTarget);
					let $item = $this.closest(basket.node.item);
					let id = $item.data("id");
					let val = $this.val();

					ajax.updateQuantity(id, val);
				}, 500)
			);
		// $(basket.node.quantityInput).on(
		// 	"change",
		// 	config.delay((e) => {
		// 		let $this = $(e.currentTarget);
		// 		let $item = $this.closest(basket.node.item);
		// 		let $itemPrice = $item.find(basket.node.itemPrice);
		// 		let id = $item.data("id");
		// 		let val = $this.val();
		// 		let price = val * parseInt($item.attr("data-price"));

		// 		$itemPrice.text(price);
		// 		ajax.updateQuantity(id, val);
		// 	}, 500)
		// );
	},
};

export { basket };
