import { config } from "../config";
import { ajax } from "./ajax";

var mobile = {

    realForm: {},

    virtualForm: {},

    realLabels: {},
    virtualLabels: {},

    // 

    get fieldMutations(){
        const $fields = $('.js-mobile-filter-item input, .js-category input');
        const $priceFields = $('.js-mobile-filter-cost .js-cost-input');
        

        return {
            set(parent, name){
                const $panel = $(parent).find('.filter-scroll__categories-list');
                // const name = $(this).attr('name');
                const $link = $(`.filter-scroll__category-item[data-category-slug="${name}"]`);
                const $linkList = $link.find('.filter-scroll__category-chosen-variants');

                // console.log('link is', name, $link)

                $linkList.empty();

                if (name == 'categories') {

                    mobile.virtualForm[name] = [];
                    mobile.virtualLabels[name] = [];

                }else{
                    if (!mobile.virtualForm.tags) mobile.virtualForm.tags = {};
                    if (!mobile.virtualLabels.tags) mobile.virtualLabels.tags = {};

                    mobile.virtualForm.tags[name] = [];
                    mobile.virtualLabels.tags[name] = [];

                }

                $panel.find('input:checked').each(function(){
                    const value = $(this).val();
                    const text = $.trim($(this).parent().text())

                    if (name == 'categories'){
                        mobile.virtualForm[name].push(value);
                        mobile.virtualLabels[name].push(text);                        
                    }else{
                        mobile.virtualForm.tags[name].push(value);
                        mobile.virtualLabels.tags[name].push(text);
                    }
                    

                    
                    $linkList.append(`<span class="filter-scroll__category-chosen-variant">${text}</span>`)
                })

                // console.log('virtual form', mobile.virtualForm)

            },
            priceSet(){
                const $linkList = $('*[data-category-slug="cost"] .filter-scroll__category-chosen-variants');

                $linkList.empty();

                mobile.virtualForm.cost = {};
                mobile.virtualLabels.cost = {};

                $priceFields.each(function(){
                    const name = $(this).attr('name');
                    const value = $(this).val();

                    mobile.virtualForm.cost[name] = Number(value.replace(/ /g, ""));
                    mobile.virtualLabels.cost[name] = `$${value}`;

                    $linkList.append(`<span class="filter-scroll__category-chosen-variant is-dash">$${value}</span>`)
                })

                // console.log('virtual form', mobile.virtualForm)
            }
        }
    },

    fieldEvents(){
        // const $fields = $('.js-mobile-filter-item input, .js-category input');
        // const $priceFields = $('.js-mobile-filter-cost .js-cost-input');
        // console.log('price fields', $priceFields)

        // const mutations = this.fieldMutations;

        // $fields.on('change', mutations.set)
        // $priceFields.on('change', mutations.priceSet)
    },

    get subCategories() {
        const subCategoriesWindow = $('.js-select-mobile-subcategories')
        const subCategories = $('.js-mobile-subcategory')

        return {
            open() {
                subCategoriesWindow.addClass('is-active');
            },
            close() {
                subCategoriesWindow.removeClass('is-active');
            },
            openCategoryById(id) {
                this.closeAllSubcategoriesWindows()
                const subCategoriesWindow = subCategories.filter(`[data-category-id=${id}]`);
                subCategoriesWindow.addClass('is-active')
            },
            closeAllSubcategoriesWindows() {
                subCategories.removeClass('is-active');
            }
        }
    },
    get logoMobileCategories() {
        const logo = $('.js-mobile-categories-logo');

        return {
            show() {
                logo.addClass('is-active')
            },
            hide() {
                logo.removeClass('is-active')
            },
        }
    },
    get backMobileCategories() {
        const back = $('.js-mobile-categories-back');

        return {
            show() {
                back.addClass('is-active')
            },
            hide() {
                back.removeClass('is-active')
            },
            onClick(fn) {
                back.on('click', fn)
            },
        }
    },
    get mobileCategories() {
        // console.log(1111);
        const mobileCategoriesWindow = $('.js-mobile-categories')
        const subCategories = this.subCategories;
        let step = 'categories';
        const back = this.backMobileCategories;
        const logo = this.logoMobileCategories;

        return {
            toggleNav() {
                if (step === 'categories') {
                    back.hide()
                    logo.show()
                }

                if (step === 'subcategories') {
                    back.show()
                    logo.hide()
                }
            },
            open() {
                step = 'categories';
                mobileCategoriesWindow.addClass('is-active');

                
            },
            close() {
                step = 'subcategories';
                mobileCategoriesWindow.removeClass('is-active');

                
            },
            setAll(){
                const $all = $('.js-all-category');

                if($all.is(':checked')){
                    $('.filter-scroll__categories-list input').prop('checked', false).trigger("change")
                }
            
            },
            rebuild(){

                if (JSON.stringify(mobile.realForm) === JSON.stringify(mobile.virtualForm)) return false;

                const formClone = JSON.parse(JSON.stringify(mobile.realForm));

                mobile.virtualForm = formClone;

                // console.log('rebiuild menu', mobile.realForm)

                $('.filter-scroll__category-chosen-variants').empty()
                $('.js-mobile-filter-item input, .js-category input').prop('checked', false)
                
                for (let i in mobile.realForm) {

                    if(i !== "cost"){

                        const $link = $(`.filter-scroll__category-item[data-category-slug="${i}"]`);
                        const $linkList = $link.find('.filter-scroll__category-chosen-variants');

                        mobile.realLabels[i].forEach(text => {
                            $linkList.append(`<span class="filter-scroll__category-chosen-variant">${text}</span>`)
                        });

                        mobile.realForm[i].forEach(val => {
                            $(`.js-mobile-filter-item input[name="${i}"][value="${val}"]`).prop('checked', true)
                            $(`.js-category input[name="${i}"][value="${val}"]`).prop('checked', true)
                        });  

                    }else{
                        const $linkList = $('*[data-category-slug="cost"] .filter-scroll__category-chosen-variants');
                        
                        for (let cost in mobile.realLabels[i]) {
                            $linkList.append(`<span class="filter-scroll__category-chosen-variant is-dash">${mobile.realLabels[i][cost]}</span>`)
                        }
                        
                        // console.log("cost", mobile.realForm[i]['cost_min'], mobile.realForm[i]['cost_max'])

                        const $min = $(".js-mobile-filter-cost .js-cost-from");
                        const $max = $(".js-mobile-filter-cost .js-cost-to"); 
                        
                        $min.val(config.thousandSeparator(mobile.realForm[i]['cost_min'])).attr('data-value', mobile.realForm[i]['cost_min'])//.trigger('keyup');
                        $max.val(config.thousandSeparator(mobile.realForm[i]['cost_max'])).attr('data-value', mobile.realForm[i]['cost_max'])//.trigger('keyup');

                        $(".js-mobile-filter-cost .js-cost-slider").slider({
                            values: [
                                mobile.realForm[i]['cost_min'],
                                mobile.realForm[i]['cost_max'],
                            ],
                        });                        
                        
                        // $(".js-mobile-filter-cost .js-cost-slider").slider('values', mobile.realForm[i]['cost_min'], mobile.realForm[i]['cost_max'])
                        
                    }
                }

            },
            apply(){
                const formClone = JSON.parse(JSON.stringify(mobile.virtualForm));
                const labelsClone = JSON.parse(JSON.stringify(mobile.virtualLabels));

                mobile.realForm = formClone;
                mobile.realLabels = labelsClone;

                // console.log(mobile.realForm)

                // ajax send realForm
                ajax.getProductsMobile(mobile.realForm);
            },
            next(id) {
                this.close();
                this.toggleNav();
                subCategories.open();
                subCategories.openCategoryById(id);
            },
            prev() {
                this.open();
                this.toggleNav();
                subCategories.closeAllSubcategoriesWindows();
            },
            reset() {
                this.open();
                this.toggleNav();
                subCategories.closeAllSubcategoriesWindows();
            }
        }
    },

    initMobileCategories() {
        const categories = $('.js-mobile-category');
        const mobileCategories = this.mobileCategories;
        const back = this.backMobileCategories;

        back.onClick(() => {
            mobileCategories.prev();
        });

        categories.on('click', function () {
            const id = $(this).data('category-id');
            mobileCategories.next(id);
        });

        $('.js-mobile-close').on('click', function () {
            mobileCategories.reset();
            mobileCategories.rebuild();
            
        });

        $('.js-mobile-apply').on('click', function () {
            mobileCategories.apply();
            mobileCategories.reset();
            mobile.close()
        });        

        $('.js-all-category').on('change', mobileCategories.setAll)

       
       
        $('.filter-scroll__categories-list input').on('change', () => {
            const $all = $('.js-all-category');
            const $inputs = $('.filter-scroll__categories-list input:checked')

            if ($inputs.length){
                $all.prop('checked', false)
            }
        })

        // 

        const btns = $('.js-category-item');

        btns.on('click', function () {
            $('.mobile__nav-link[data-mobile="#category"]').trigger('click');

            if ($(this).attr('data-category-mobile-id')){
                const id = $(this).data('category-mobile-id');
                mobileCategories.next(id);                
            }

        });
    },

    get allFiltersWindow() {
        const allFiltersWindow = $('.js-mobile-all-filters');

        return {
            open() {
                allFiltersWindow.addClass('active');
            },
            close() {
                allFiltersWindow.removeClass('active');
            }
        }
    },

    get filtersWindow() {
        const filtersWindow = $('.js-filters-window');

        return {
            open() {
                filtersWindow.addClass('active');
            },
            close() {
                filtersWindow.removeClass('active');
            }
        }
    },


    get catsWindow() {
        const catWindow = $('.js-mobile-all-categories');

        return {
            open() {
                catWindow.addClass('active');
            },
            close() {
                catWindow.removeClass('active');
            }
        }
    },    
    
    get filterCostWindow() {
        const filterCostWindow = $('.js-mobile-filter-cost');

        return {
            open() {
                filterCostWindow.addClass('active');
            },
            close() {
                filterCostWindow.removeClass('active');
            }
        }
    },

    get filterWindow() {
        let step = 'main'; // categories | cost | filter
        const allCategoriesWindow = this.allFiltersWindow;
        const filtersWindow = this.filtersWindow;
        const catsWindow = this.catsWindow;
        const mobileCategories = this.mobileCategories;
        const mutations = this.fieldMutations;
        const filterCostWindow = this.filterCostWindow;
        const filter = $('#filter');

        return {
            get backBtn() {
                return filter.find('.js-filter-back-to-screen');
            },
            get backToFiltersBtn() {
                return filter.find('.js-filter-back-to-filters');
            },
            get title() {
                const title = filter.find('.js-filter-title');
                return {
                    change(value) {
                        title.html(value);
                        return title;
                    }
                };
            },
            get deleteBtn() {
                return filter.find('.js-filter-delete');
            },
            closeAllWindow() {
                
                allCategoriesWindow.close();
                catsWindow.close();
                filtersWindow.close();
                filterCostWindow.close();
            },
            openFilter(slug) {
                step = "filter";
                const filterWindow = filter.find(`[data-category-slug="${slug}"]`);
                this.closeAllWindow();
                filter.find('[data-category-slug]').removeClass('active');
                filterWindow.addClass('active');
                filtersWindow.open();
            },
            openCost(){
                step = "cost";
                this.closeAllWindow();
                filterCostWindow.open()             
            },

            openCategories() {
                step = "categories";
                this.closeAllWindow();
                catsWindow.open();
            },

            goToBack(){
                // const filterWindow = filter.find(`[data-category-slug]`);
                // // this.closeAllWindow();
            },
            reset() {
                step = "main";
                this.closeAllWindow();
                allCategoriesWindow.open();
            },
            init() {
                const filterWindow = this;

                $('[data-mobile="#filter"]').on('click', function () {
                    $('.js-mobile-close').eq(0).addClass('is-active');
                    filterWindow.reset();
                });

                filterWindow.deleteBtn.on('click', function(){

                    mobile.virtualForm = {};
                    mobile.virtualLabels = {};

                    $('.filter-scroll__category-chosen-variants').empty()
                    $('.js-mobile-filter-item input, .js-category input').prop('checked', false)
                    
                    const $list = $(".js-mobile-filter-cost .js-cost");
                    const $min = $(".js-mobile-filter-cost .js-cost-from");
                    const $max = $(".js-mobile-filter-cost .js-cost-to");

                    const valMin = Number($list.attr('data-min'));
                    const valMax = Number($list.attr('data-max'));
                    const sepMin = config.thousandSeparator(valMin);
                    const sepMax = config.thousandSeparator(valMax);

                    $min.val(sepMin).attr('data-value', valMin)
                    $max.val(sepMax).attr('data-value', valMax)

                    $(".js-mobile-filter-cost .js-cost-slider").slider({
                        values: [
                            valMin,
                            valMax,
                        ],
                    });      
                    
                    filterWindow.reset();
                })

                filterWindow.backBtn.on('click', function(){
                    if (step !== 'main'){
                        const $activeWindow = $('.js-subparam-parent.active');

                        if ($activeWindow.hasClass('js-mobile-filter-cost')){

                            const $list = $(".js-mobile-filter-cost .js-cost");
                            const $min = $(".js-mobile-filter-cost .js-cost-from");
                            const $max = $(".js-mobile-filter-cost .js-cost-to");

                            const minName = $min.attr('name');
                            const maxName = $max.attr('name');
                            

                            const valMin = mobile.virtualForm['cost'] !== undefined ? mobile.virtualForm['cost'][minName] : Number($list.attr('data-min'));
                            const valMax = mobile.virtualForm['cost'] !== undefined ? mobile.virtualForm['cost'][maxName] : Number($list.attr('data-max'));

                            const sepMin = config.thousandSeparator(valMin);
                            const sepMax = config.thousandSeparator(valMax);

                            $min.val(sepMin).attr('data-value', valMin)
                            $max.val(sepMax).attr('data-value', valMax)

                            $(".js-mobile-filter-cost .js-cost-slider").slider({
                                values: [
                                    valMin,
                                    valMax,
                                ],
                            });                              

                        }else{

                            let name = $activeWindow.attr('data-category-slug');
                            const $list = $activeWindow.find(':input');

                            if ($activeWindow.hasClass('js-filters-window')) {
                                name = $activeWindow.find('.active[data-category-slug]').attr('data-category-slug');
                            }

                            // console.log(name, mobile.virtualForm)

                            // || mobile.virtualForm.tags[name] !== undefined
                            if (mobile.virtualForm[name] !== undefined ){
                                $list.each(function(){

                                    const val = $(this).val();
                                    const inArray = val !== undefined ? (name == 'categories' ? mobile.virtualForm[name].includes(val) : mobile.virtualForm.tags[name].includes(val)) : false;

                                    $(this).prop('checked', inArray)

                                })                                
                            }else{
                                $list.prop('checked', false)
                            }

                        }

                        filterWindow.reset();

                    }else{
                        filterWindow.closeAllWindow();

                        mobile.close()

                        mobileCategories.rebuild();                        
                                                
                    }
                })

                $('.js-subparam-apply').on('click', function(){


                    if($(this).attr('data-type-price')){
                        mutations.priceSet();
                    } else if ($(this).attr('data-type-category')){
                        const $all = $('.js-all-category');
                        
                        if ($all.is(':checked')) {
                            delete mobile.virtualForm.categories;
                            $('[data-category-slug="categories"] .filter-scroll__category-chosen-variant').remove()                            
                        }else{
                            const $panel = $(this).closest('.js-subparam-parent');
                            const name = $panel.attr('data-category-slug');
                            mutations.set($panel, name);
                        }                        

                    }else{
                        const $panel = $(this).closest('.js-subparam-parent').find('.active[data-category-slug]');
                        const name = $panel.attr('data-category-slug');

                        mutations.set($panel, name);
                    }
                
                })

                $('.js-open-mobile-all-categories').on('click', function () {
                    filterWindow.openCategories();
                });

                $('.js-open-mobile-cost').on('click', function () {
                    filterWindow.openCost()
                });

                // $('.js-close-mobile').on('click', function () {

                // });                

                $('.js-open-mobile-filter').on('click', function () {
                    const btn = $(this);
                    const slug = btn.data('category-slug');
                    filterWindow.openFilter(slug);
                });
            },
        }
    },

    initFilter() {
        this.filterWindow.init();
    },

    open: () => {
        $('.js-mobile-open').click(function () {
            let mobilePanel = $(this).data('mobile');

            $('.js-mobile-close.is-active').removeClass('is-active');
            $(this).parent().find('.js-mobile-close').addClass('is-active');

            $('.mobile__container:visible').hide();
            $('.mobile__container' + mobilePanel).show();

            $('html, body').addClass('js-lock');

            // console.log("open objects", mobile.realForm, mobile.virtualForm)
            
        });
    },
    close: () => {
        $('.js-mobile-close').removeClass('is-active');
        $('.mobile__container:visible').hide();
        $('html, body').removeClass('js-lock');

        // console.log("close objects", mobile.realForm, mobile.virtualForm)
    },
    toggleCategory: () => {
        $('.js-submenu-toggle').click(function () {
            let parrent = $(this).closest('.mobile-category__item');

            if (parrent.hasClass('is-active')) {
                parrent.removeClass('is-active');
                parrent.find('.mobile-category__sub:visible').slideUp(300);
            } else {
                $('.mobile-category__item.is-active').removeClass('is-active').find('.mobile-category__sub:visible').slideUp(300);

                parrent.addClass('is-active');
                parrent.find('.mobile-category__sub').slideDown(300);
            }

            return false;
        });
    },
    toggleSidebar: () => {
        $('.js-show-sidebar').click(function () {
            let id = $(this).attr('href');

            $(id).toggleClass('is-active');
            $(this).toggleClass('is-active');
            $('html, body').toggleClass('js-lock');

            return false;
        });
    },

    init: () => {
        mobile.open();
        mobile.close();
        mobile.toggleCategory();
        mobile.toggleSidebar();
        mobile.initMobileCategories();
        mobile.initFilter();
        // mobile.fieldEvents()

        $('.js-mobile-close').click(mobile.close);        
    }
}

export {mobile}
