var mainCategories = {
  init: () => {
    const categories = $('.js-main-category-item')

    categories.mouseenter(function () {
      $(this).find('.main-category').addClass('active')
    })

    categories.mouseleave(function () {
      $(this).find('.main-category').removeClass('active')
    })
  },
}

export { mainCategories }
