import Parallax from "parallax-js";

var prlx = {
	selector: ".js-scene",
	parallaxInstance: [],

	run: (selector, index) => {
		let params = {
			scalarX: 10,
			scalarY: 10,
		};

		if ($(selector).attr("data-limit-y")) params["limitY"] = 0;

		prlx.parallaxInstance[index] = new Parallax(selector, params);
	},

	init: () => {
		if (!$(prlx.selector).length) return false;

		$(prlx.selector).each((i, el) => {
			prlx.run(el, i);
		});
	},
};

export { prlx };
